import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SimpleButton from "../../../../../buttons/SimpleButton";
import "../title-section.scss";

const TitleSection = ({ title, lighttitle = false, icon = faTrash, canDelete = true, deleteMethod = () => { }, children }) => {
  const titleClass = !lighttitle ? "titlesection" : "titlesection light-title";

  return (
    <div className={titleClass}>
      <div className="title-block">
        <FontAwesomeIcon icon={icon} className="title-section-icon" />
        <h1>{title}</h1>
      </div>
      <div className="delete-block">
        {children}
        <SimpleButton title="Delete" icon={faTrash} disabled={!canDelete} onClick={deleteMethod} />
      </div>
    </div>
  );
}

export default TitleSection;

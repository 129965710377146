import MainHeader from '../../components/sections/MainHeader';
import '../../scss/components/sections.scss';
import '../../scss/components/navbar.scss';

const Screenshots = () => {
  return (
    <div>
      <MainHeader active="screenshots" />
    </div>
  );
}

export default Screenshots;
import React, { useState, useEffect } from "react";
import ApiService from "../../auth/ApiService";
import { DateTime } from "luxon";
import InputContainer from "./InputContainer";
import LabeledFileInput from "../formcomponents/LabeledFileInput";
import ErrorHandling from "../../utils/errors/ErrorHandling";
import "../../scss/components/sections.scss";

const PhotoUploadInputType = ({ jobcard, jobItemStatuses }) => {
  const [fileBytes, setFileBytes] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [saving, setSaving] = useState(false);

  const [prevImageUrl, setPrevImageUrl] = useState();

  const [hasDifferences, setHasDifferences] = useState(false);

  useEffect(() => {
    if (jobcard?.inputTypeSpecification?.photo) {
      setPrevImageUrl(jobcard.inputTypeSpecification.photo.photoUrl);
      setImageUrl(jobcard.inputTypeSpecification.photo.photoUrl);
    }
  }, [jobcard]);

  useEffect(() => {
    // Convert base64 to data URL
    if (fileBytes) {
      const dataUrl = `data:image/jpeg;base64,${fileBytes}`;
      setImageUrl(dataUrl);
    }
  }, [fileBytes]);

  useEffect(() => {
    if (imageUrl !== prevImageUrl) {
      setHasDifferences(true);
    } else {
      setHasDifferences(false);
    }
  }, [imageUrl]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      setFileBytes(base64String);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    if (jobcard) {
      try {
        setSaving(true);
        const response = await ApiService.patch('JobCards', {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          id: jobcard.id,
          inputTypeSpecification: {
            id: jobcard.inputTypeSpecification.id,
            inputType: jobcard.inputTypeSpecification.inputType,
            photo: {
              media: {
                mediaDate: DateTime.now().toJSDate(),
                mediaType: "Image",
                fileBytes
              }
            }
          }
        });

        if (!response?.data?.jobCard) return ErrorHandling.toastifyError("Job Card Item Update Failed!");
        setPrevImageUrl(imageUrl);
        setHasDifferences(false);
        return ErrorHandling.toastifySuccess("Job Card Item Updated!");
      } catch (error) {
        ErrorHandling.toastifyError("Update Failed due to Unexpected Error!");
      } finally {
        setSaving(false);
      }
    }
  }

  const discardChanges = () => {
    setImageUrl(prevImageUrl);
  }

  return (
    <InputContainer jobcard={jobcard} jobItemStatuses={jobItemStatuses} onSubmit={saveChanges} loading={saving} hasDifferences={hasDifferences} onUndo={discardChanges}>
      <LabeledFileInput label="Select Image:" fileChange={handleFileChange} />
      {imageUrl ? <img src={imageUrl} alt="Preview" className="image-preview" /> : null}
    </InputContainer>
  );
}

export default PhotoUploadInputType;

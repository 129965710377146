// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  .loader-container p {
    font-size: 1rem;
    color: #72B2C5;
    margin: 0;
    padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/widgets/TextLoader/text-loader.scss","webpack://./src/scss/mixins/_flex-layout.scss","webpack://./src/scss/variables/_typography.scss","webpack://./src/scss/variables/_colors.scss"],"names":[],"mappings":"AAEA;ECDI,aAAa;EACb,sBDCsC;ECAtC,uBDA8C;ECC9C,mBDDsD;ECMtD,WAAW;EDLX,YAAY,EAAA;EAFhB;IAKQ,eEHoB;IFIpB,cGPmB;IHQnB,SAAS;IACT,UAAU,EAAA","sourcesContent":["@import '../../../scss/main.scss';\n\n.loader-container {\n    @include flex-layout-full-width(column, center, center);\n    height: 100%;\n\n    p {\n        font-size: $common-font-size-1rem;\n        color: $arctic-ocean-blue;\n        margin: 0;\n        padding: 0;\n    }\n}","@mixin flex-layout($direction: row, $justify: flex-start, $align: flex-start) {\n    display: flex;\n    flex-direction: $direction;\n    justify-content: $justify;\n    align-items: $align;\n}\n\n@mixin flex-layout-full-width($direction: row, $justify: flex-start, $align: flex-start) {\n    @include flex-layout($direction, $justify, $align);\n    width: 100%;\n}\n\n@mixin flex-layout-custom-width($direction: row, $justify: flex-start, $align: flex-start, $width: 100%) {\n    @include flex-layout($direction, $justify, $align);\n    width: $width;\n}","// Font Sizes\n$common-font-size: 12px;\n$common-font-size-large: 16px;\n$common-font-size-xlarge: 20px;\n$common-font-size-1rem: 1rem;\n$common-font-size-small: 0.75rem;","// Colors\n$arctic-ocean-blue: #72B2C5;\n$arctic-ocean-blue-dark: #4F8A9B;\n$arctic-ocean-blue-light: #AEDFE6;\n$arctic-ocean-blue-pale: #7BBFCE;\n$black: #000;\n$blue: #0000ff;\n$deep-red: #d32f2f;\n$grey: #808080;\n$light-grey: #ccc;\n$medium-light-grey: #ddd;\n$pale-grey: #e3e3e3;\n$red: #ff0000;\n$orange: #ffa500;\n$soft-red: #e57373;\n$soft-white: #FAFCFD;\n$very-dark-grey: #333;\n$very-light-grey: #f7f7f7;\n$white: #fff;\n$yellow: #ffff00;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import ApiService from '../../../../../auth/ApiService';
import LabeledSelect from "../../../../formcomponents/LabeledSelect";
import "./send-job-to-route.scss";

const SendJobToRoute = ({ selectedRoute, onRouteSelect = () => {}, onSubmit = () => {} }) => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        setLoading(true);
        const response = await ApiService.get('routes');
        if (response?.data?.routes) {
          const newRoutes = response.data.routes.filter(route => route.routeStatus === "New");
          console.log(newRoutes);
          setRoutes(newRoutes.length > 0 ? newRoutes.map(route => ({
            value: route.id,
            label: route.routeName
          })) : routes);
        }
      } catch (error) {
        console.error('Error fetching new routes: ', error);
      } finally {
        setLoading(false);
      }
    }

    fetchRoutes();
  }, []);

  return (
    <div className="send-to-route">
      <div className="action-block">
        <h3>Send Job To Route:</h3>
        <div className="fill">
          <LabeledSelect
            options={routes}
            isDisabled={false}
            isLoading={loading}
            isClearable={true}
            isSearchable={true}
            name="routeselect"
            onChange={onRouteSelect}
            value={selectedRoute}
          />
        </div>
        <div className="button-block">
          <button className="create-button" type="button" onClick={onSubmit}>Send</button>
        </div>
      </div>
    </div>
  );
}

export default SendJobToRoute;

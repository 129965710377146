import { faBriefcase, faTruck, faUserFriends, faCar, faFirstAid, faFolderOpen } from '@fortawesome/free-solid-svg-icons';

const menuItems = [
  {
    category: 'Jobs & Tasks',
    icon: faBriefcase,
    items: [
      { label: 'Jobs', path: '/sterna/dashboard/jobs/open' },
      { label: 'Tickets', path: '/sterna/dashboard/tickets/open' }
    ],
  },
  {
    category: 'Logistics',
    icon: faTruck,
    items: [
      { label: 'Routes', path: '/sterna/dashboard/routes/all' },
      { label: 'Deliveries', path: '/sterna/dashboard/deliveries' }
    ],
  },
  {
    category: 'Fleet Management',
    icon: faCar,
    items: [
      { label: 'Vehicles', path: '/sterna/dashboard/vehicles/all' },
      { label: 'Fuel Log', path: '/sterna/dashboard/fleetfuel/all' },
      { label: 'Maintenance', path: '/sterna/dashboard/fleetmaintenance/all' },
      { label: 'Fleet Reports', path: '/sterna/dashboard/fleetreports' }
    ]
  },
  {
    category: 'Management',
    icon: faUserFriends,
    items: [
      { label: 'Company', path: '/sterna/dashboard/companyprofile' },
      { label: 'Clients', path: '/sterna/dashboard/clients/all' },
      { label: 'Sites', path: '/sterna/dashboard/sites/all' },
      { label: 'My Profile', path: '/sterna/dashboard/userprofile' }
    ],
  },
  {
    category: 'Health & Safety',
    icon: faFirstAid,
    items: [
      { label: 'Inspections', path: '/sterna/dashboard/sheinspections' },
      { label: 'Risk Assessments', path: '/sterna/dashboard/sheriskassessments' }
    ],
  },
  {
    category: 'Directory',
    icon: faFolderOpen,
    items: [
      { label: 'Documents', path: '/sterna/dashboard/documents/all' },
    ],
  },
];

export default menuItems;
import React from "react";
import NoItemSelected from "../../../../other/NoItemSelected";
import TextLoadingIndicator from "../../../../../../widgets/TextLoader";
import JobDetails from "../JobDetails";

const TabRenderer = ({ job, scaffoldLoading, scaffold, jobTypes, jobPriorities, jobItemStatuses, reports, triggerUpdate, tab }) => {
  if (!job) return <NoItemSelected item="Job" />;
  if (scaffoldLoading) return <TextLoadingIndicator text="Retrieving Job Card" />;
  if (scaffold) return <JobDetails
    job={job}
    scaffold={scaffold}
    jobTypes={jobTypes}
    jobPriorities={jobPriorities}
    jobItemStatuses={jobItemStatuses}
    reports={reports}
    triggerUpdate={triggerUpdate}
    tab={tab}
  />;
  return <NoItemSelected item="Job" />;
}

export default TabRenderer;
import React from "react";
import { colourPicker, getJobItemStatusColour } from "../../utils/colour/Colours";
import { enumChecker } from "../../utils/enums/Enums";
import "../../scss/components/sections.scss";
import expansionImage from "../../images/icons/expanded.png";

const InputTypeHeader = ({ jobcard, jobItemStatuses, expanded, setExpanded = () => {}, hasDifferences = false }) => {
  const sequence = jobcard?.sequence ?? -1;
  const jobItemName = jobcard?.jobItem?.jobItemName ?? "Unknown";
  const jobItemDescription = jobcard?.jobItem?.jobItemDescription ?? "No details...";
  const jobItemStatus = jobcard?.jobItemStatus ?? "N/A";

  return (
    <>
      <div className="input-box" style={{ justifyContent: "space-between", width: "100%" }}>
        <div className="input-title">
          <h3>{`Job Card Item ${sequence ?? "#"}:`}</h3>
          <span className="input-name">{jobItemName ?? "Unknown"}</span>
          <span className="inputstatus-pill" style={{ backgroundColor: colourPicker(getJobItemStatusColour, jobItemStatuses, jobItemStatus) }}>
            {enumChecker(jobItemStatus, jobItemStatuses)}
          </span>
        </div>
        <div className="input-box">
          {!expanded && hasDifferences ? <p>Unsaved Changes!</p> : null}
          <img src={expansionImage} className={`expansion-button ${expanded ? 'expanded' : ''}`} style={{ marginRight: "5px" }} onClick={setExpanded} />
        </div>
      </div>
      <div className="input-info">
        <div className="input-field">
          <span>{`⚙️ ${jobItemDescription ?? "No description"}`}</span>
        </div>
      </div>
    </>
  );
}

export default InputTypeHeader;

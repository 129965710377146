import React, { useState, useEffect } from 'react';
import ApiService from '../../../../auth/ApiService';
import FormValidator from '../../../../utils/validation/FormValidator';
import ErrorHandling from '../../../../utils/errors/ErrorHandling';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { newJobValidations } from '../../../../utils/validation/sets/jobValidations';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import SideFormContainer from '../../../../components/containers/SideFormContainer';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import LabeledInput from '../../../../components/formcomponents/LabeledInput';
import LabeledMultilineInput from '../../../../components/formcomponents/LabeledMultilineInput';
import LabeledSelect from '../../../../components/formcomponents/LabeledSelect';
import LabeledDateTimePicker from '../../../../components/formcomponents/LabeledDateTimePicker';

import 'react-toastify/dist/ReactToastify.css';
import './jobs-new.scss';
import LabeledCheckbox from '../../../../components/formcomponents/LabeledCheckbox';

const JobsNew = () => {
  const navigate = useNavigate();

  const [sites, setSites] = useState([{ value: "0", label: "No Sites" }]);
  const [jobTypes, setJobTypes] = useState([{ value: "0", label: "Delivery" }]);
  const [jobPriorities, setJobPriorities] = useState([{ value: "0", label: "None" }]);

  const [loadingSites, setLoadingSites] = useState(true);
  const [loadingJobTypes, setLoadingJobTypes] = useState(true);
  const [loadingJobPriorities, setLoadingJobPriorities] = useState(true);
  const [creating, setCreating] = useState(false);

  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [jobType, setJobType] = useState();
  const [jobPriority, setJobPriority] = useState();
  const [site, setSite] = useState();
  const [dueDate, setDueDate] = useState(DateTime.now().toJSDate());
  const [ticket, setTicket] = useState(false);

  useEffect(() => {
    const fetchSitesData = async () => {
      try {
        setLoadingSites(true);
        const response = await ApiService.get('sites');
        if (response?.data?.clientSites) {
          const siteData = response.data.clientSites.flatMap(clientSite => clientSite.sites);
          setSites(siteData.length > 0 ? siteData.map(site => ({
            value: site.id,
            label: `${site.siteCode ? `${site.siteCode} | ` : ""}${site.siteAlternateId ? `${site.siteAlternateId} | ` : ""}${site.siteName}`
          })) : sites);
        }
      } catch (error) {
        console.error('Error fetching client sites: ', error);
      } finally {
        setLoadingSites(false);
      }
    };

    const fetchJobTypesData = async () => {
      try {
        setLoadingJobTypes(true);
        const response = await ApiService.get('enums/Job/JobType');
        if (response?.data?.options) {
          setJobTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job types: ', error);
      } finally {
        setLoadingJobTypes(false);
      }
    }

    const fetchJobPrioritiesData = async () => {
      try {
        setLoadingJobPriorities(true);
        const response = await ApiService.get('enums/Job/JobPriority');
        if (response?.data?.options) {
          setJobPriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job priorities: ', error);
      } finally {
        setLoadingJobPriorities(false);
      }
    }

    fetchSitesData();
    fetchJobTypesData();
    fetchJobPrioritiesData();
  }, []);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  }

  const handleJobTypeChange = (selectedOption) => {
    setJobType(selectedOption);
  }

  const handleJobPriorityChange = (selectedOption) => {
    setJobPriority(selectedOption);
  }

  const handleSiteChange = (selectedOption) => {
    setSite(selectedOption);
  }

  const handleDueDateChange = (newDate) => {
    setDueDate(newDate);
  }

  const handleTicketToggle = () => {
    setTicket(!ticket);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validator = new FormValidator({ description, notes, jobType, jobPriority, site, dueDate }, newJobValidations);

    if (validator.validate()) {
      try {
        setCreating(true);
        let ticketId;

        // Check if this job must have a linked ticket
        // Create a ticket if the job needs one
        // Set the ticketId so that we can set it when we build the job request payload
        if (ticket) {
          // First get the site details:
          const siteResponse = await ApiService.get(`sites/${site.value}`);
          if (!siteResponse?.data?.site) {
            return ErrorHandling.toastifyError("Site not found! Ticket not created!");
          }
          if (siteResponse?.data?.site.id == '00000000-0000-0000-0000-000000000000'){
            return ErrorHandling.toastifyError("Unknown Site Id! Ticket not created!");
          }

          // Then build the ticket request payload:
          const ticketdata = {
            apiKey: process.env.REACT_APP_MASTER_API_KEY,
            siteId: site.value,
            clientId: siteResponse.data.site.clientId,
            dueDate,
            notes,
            comments: "",
            priority: jobPriority.value
          };
          
          // Create the ticket:
          const ticketResponse = await ApiService.post('tickets', ticketdata);
          console.log(ticketResponse);
          if (ticketResponse?.data?.ticket?.id) {
            if (ticketResponse.data.ticket.id == '00000000-0000-0000-0000-000000000000') {
              return ErrorHandling.toastifyError(response.data.message ?? "Failed to create ticket!");
            }
            
            ticketId = ticketResponse.data.ticket.id;
          }
        }

        // Build the job request payload:
        const data = {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          job: {
            siteId: site.value,
            jobDescription: description,
            jobType: jobType.value,
            dueByDate: dueDate,
            jobPriority: jobPriority.value,
            hasTicket: ticket,
            ticketId,
            notes
          }
        };

        // Create the job:
        const response = await ApiService.post('jobs', data);
        if (response?.data?.jobId) {
          if (response.data.jobId == '00000000-0000-0000-0000-000000000000') {
            return ErrorHandling.toastifyError(response.data.message);
          }

          ErrorHandling.toastifySuccess("Job Created!");

          // Clean up form components, set to defaults:
          setDescription('');
          setNotes('');
          setJobType(undefined);
          setJobPriority(undefined);
          setSite(undefined);
          setDueDate(DateTime.now().toJSDate());
          setTicket(false);
          
          // Navigate to job open page:
          setTimeout(() => {
            navigate('/sterna/dashboard/jobs/open');
          }, 5000);
        } else {
          ErrorHandling.toastifyError(response?.data?.message ?? "Unknown call error!");
        }
      } catch {
        ErrorHandling.toastifyError("Job Creation Failed!");
      } finally {
        setCreating(false);
      }
    }
  }

  return (
    <div className='jobs-new-container'>
      <SideFormContainer
        formtitle="Initiate New Work"
        icon={faBriefcase}
        buttonTitle="Create Job"
        loading={creating}
        onSubmit={handleSubmit}
        fancy
      >
        <LabeledInput
          label="Description:"
          placeholder="Job Description"
          value={description}
          onChange={handleDescriptionChange}
        />
        <LabeledMultilineInput
          label="Notes:"
          placeholder="Job Summary"
          value={notes}
          onChange={handleNotesChange}
        />
        <LabeledSelect
          label="Site:"
          options={sites}
          isDisabled={false}
          isLoading={loadingSites}
          isClearable={true}
          isSearchable={true}
          name="siteselect"
          onChange={handleSiteChange}
          value={site}
        />
        <LabeledSelect
          label="Job Type:"
          options={jobTypes}
          isDisabled={false}
          isLoading={loadingJobTypes}
          isClearable={true}
          isSearchable={true}
          name="typeselect"
          onChange={handleJobTypeChange}
          value={jobType}
        />
        <LabeledSelect
          label="Priority:"
          options={jobPriorities}
          isDisabled={false}
          isLoading={loadingJobPriorities}
          isClearable={true}
          isSearchable={true}
          name="priorityselect"
          onChange={handleJobPriorityChange}
          value={jobPriority}
        />
        <LabeledDateTimePicker
          label="Due Date:"
          date={dueDate}
          onSelect={() => { }}
          onChange={handleDueDateChange}
        />
        <LabeledCheckbox
          label="Mark this as a Ticket"
          checked={ticket}
          onChange={handleTicketToggle}
        />
      </SideFormContainer>
      <ItemDetailContainer nopadding>

      </ItemDetailContainer>
    </div>
  );
}

export default JobsNew;
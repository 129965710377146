export default () => {
  return {
    "Aerodrome": "#B2BEC3", // Gray (concrete runways)
    "Air BnB": "#FFB6C1", // Light Pink (friendly, welcoming color)
    "Aircraft": "#A8A8A8", // Light Gray (metallic appearance)
    "Airport": "#9EA2A2", // Grayish Blue (a mix of sky and concrete)
    "Airstrip": "#C0C0C0", // Silver (reflecting planes)
    "Bank": "#4A5A6A", // Dark Gray-Blue (institutional feel)
    "Bar": "#FFCC99", // Light Brown (relaxing bar ambiance)
    "Bed And Breakfast": "#FFE4B5", // Moccasin (cozy feel)
    "Building": "#808080", // Gray (concrete structures)
    "Bus": "#3A5F7F", // Medium Blue (public transportation)
    "Cafe": "#C79A6B", // Coffee color
    "Car": "#C3C3C3", // Metallic Silver
    "Car Wash": "#ADD8E6", // Light Blue (water and cleanliness)
    "Center": "#BDB76B", // Dark Khaki (multipurpose color)
    "Church": "#E6E6FA", // Lavender (calm and spirituality)
    "Cinema": "#FFD700", // Gold (golden screen)
    "Client": "#32CD32", // Lime Green (growth and relationships)
    "Clinic": "#E0FFFF", // Light Cyan (clean and sterile)
    "Club": "#800080", // Purple (nightlife)
    "Colliery": "#333333", // Dark Gray (coal mining)
    "Commercial Building": "#696969", // Dim Gray (commercial space)
    "Construction Client": "#A52A2A", // Brown (earth and construction)
    "Container": "#708090", // Slate Gray (metal container)
    "Dam": "#4682B4", // Steel Blue (water containment)
    "Dam Wall": "#5F9EA0", // Cadet Blue (strong wall)
    "Dealership": "#B0E0E6", // Powder Blue (friendly sales)
    "Defense Facility": "#5D8AA8", // Air Force Blue (military)
    "Dentist": "#FFFFFF", // White (clean and clinical)
    "Diner": "#F28500", // Tangerine (friendly eatery)
    "Distribution Board": "#696960", // Taupe Gray (neutral)
    "Distribution Facility": "#9C9C9C", // Silver Chalice (logistics)
    "Dock": "#556B2F", // Dark Olive Green (water and industry)
    "Doctor": "#ADD8E6", // Light Blue (medical care)
    "Electrical Client": "#FFA500", // Orange (energy and power)
    "Estate": "#3CB371", // Medium Sea Green (wealth and land)
    "Factory": "#778899", // Light Slate Gray (industrial)
    "Farm": "#6B8E23", // Olive Drab (farming land)
    "Field": "#98FB98", // Pale Green (open fields)
    "Financial Institution": "#2E8B57", // Sea Green (money)
    "Fire Station": "#FF4500", // Orange-Red (fire and urgency)
    "Flat": "#DDA0DD", // Plum (urban living)
    "Food Processing Plant": "#CD853F", // Peru (food production)
    "Gas Infrastructure": "#FFD700", // Gold (energy-related)
    "Gas Station": "#DC143C", // Crimson (fuel)
    "Generator": "#8B4513", // Saddle Brown (power generation)
    "Golf Course": "#4CBB17", // Kelly Green (grass and leisure)
    "Hall": "#DAA520", // Goldenrod (grand space)
    "Hospital": "#B0E0E6", // Powder Blue (healthcare)
    "Hostel": "#ADFF2F", // Green Yellow (budget accommodation)
    "Hotel": "#BC8F8F", // Rosy Brown (comfort and luxury)
    "House": "#A0522D", // Sienna (home and warmth)
    "Industrial Facility": "#B0C4DE", // Light Steel Blue (industry)
    "Kitchen": "#F5F5DC", // Beige (warm and homey)
    "Laboratory": "#7FFF00", // Chartreuse (science and innovation)
    "Lake": "#1E90FF", // Dodger Blue (clear water)
    "Library": "#DEB887", // BurlyWood (books and learning)
    "Lodge": "#8B4513", // Saddle Brown (rustic feel)
    "Lumberjack": "#A0522D", // Sienna (wood and nature)
    "Lumber Yard": "#CD853F", // Peru (timber)
    "Mall": "#FFC0CB", // Pink (shopping and leisure)
    "Meadow": "#7CFC00", // Lawn Green (open space)
    "Medical Facility": "#AFEEEE", // Pale Turquoise (healthcare)
    "Mine": "#8B0000", // Dark Red (earth extraction)
    "Morgue": "#A9A9A9", // Dark Gray (sober space)
    "Mosque": "#F5DEB3", // Wheat (spiritual place)
    "Motel": "#D2B48C", // Tan (roadside accommodation)
    "Mountain": "#708090", // Slate Gray (rocks and heights)
    "Nursery": "#98FB98", // Pale Green (plants and growth)
    "Office": "#B0C4DE", // Light Steel Blue (business)
    "Oil Refinery": "#808000", // Olive (oil industry)
    "Old Age Home": "#D8BFD8", // Thistle (care and comfort)
    "Outlet": "#F08080", // Light Coral (sales and deals)
    "Park": "#228B22", // Forest Green (nature and leisure)
    "Parking Lot": "#C0C0C0", // Silver (urban space)
    "Pipeline Section": "#483D8B", // Dark Slate Blue (flow and energy)
    "Plant": "#66CDAA", // Medium Aquamarine (growth)
    "Plantation": "#6B8E23", // Olive Drab (agriculture)
    "Plumbing Client": "#B0E0E6", // Powder Blue (water-related)
    "Point Of Delivery": "#32CD32", // Lime Green (delivery success)
    "Point Of Inspection": "#D3D3D3", // Light Gray (quality control)
    "Police Station": "#00008B", // Dark Blue (law enforcement)
    "Port": "#000080", // Navy (shipping)
    "Post Office": "#7B68EE", // Medium Slate Blue (communication)
    "Power Grid": "#FFD700", // Gold (energy network)
    "Power Infrastructure": "#DAA520", // Goldenrod (power system)
    "Power Pylon": "#B8860B", // Dark Goldenrod (electricity support)
    "Psychiatric Facility": "#8A2BE2", // Blue Violet (mental health)
    "Public Works": "#6A5ACD", // Slate Blue (community services)
    "Recreational Facility": "#32CD32", // Lime Green (leisure)
    "Reserve": "#228B22", // Forest Green (conservation)
    "Restaurant": "#FFA07A", // Light Salmon (dining)
    "Restroom": "#FAF0E6", // Linen (personal hygiene)
    "Retail": "#FFB6C1", // Light Pink (shopping)
    "Road Section": "#A9A9A9", // Dark Gray (transportation)
    "Roof": "#8B4513", // Saddle Brown (shelter)
    "School": "#4B0082", // Indigo (education)
    "Ship": "#1E90FF", // Dodger Blue (transportation by water)
    "Shop": "#FF69B4", // Hot Pink (retail)
    "Social Infrastructure": "#87CEFA", // Light Sky Blue (community support)
    "Solar Plant": "#FFFF00", // Yellow (renewable energy)
    "Sports Facility": "#00FA9A", // Medium Spring Green (athletics)
    "Station": "#556B2F", // Dark Olive Green (travel hub)
    "Store": "#FF69B4", // Hot Pink (retail)
    "Subway": "#696969", // Dim Gray (underground transport)
    "Tailor": "#D2B48C", // Tan (clothing creation)
    "Tannery": "#A0522D", // Sienna (leather production)
    "Telecommunications Site": "#FF6347", // Tomato (communication hub)
    "Trailer": "#A9A9A9", // Dark Gray (mobile transport)
    "Train Station": "#556B2F", // Dark Olive Green (rail hub)
    "Truck": "#708090", // Slate Gray (freight transport)
    "Unknown": "#B0C4DE", // Light Steel Blue (unidentified)
    "Valley": "#98FB98", // Pale Green (natural depression)
    "Vehicle": "#7B68EE", // Medium Slate Blue (transportation)
    "Veterinarian": "#87CEEB", // Sky Blue (animal care)
    "Warehouse": "#778899", // Light Slate Gray (storage)
    "Water Infrastructure": "#1E90FF", // Dodger Blue (water system)
    "Weather Station": "#4682B4", // Steel Blue (climate monitoring)
    "Wholesale": "#D2B48C", // Tan (bulk sales)
    "Winery": "#8B4513" // Saddle Brown (wine production)
  };
}
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  return new Intl.DateTimeFormat('en-ZA', options).format(date);
};

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);

  const dateOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const datePart = new Intl.DateTimeFormat('en-ZA', dateOptions).format(date);

  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const timePart = new Intl.DateTimeFormat('en-ZA', timeOptions).format(date);

  return `${datePart}, ${timePart}`;
};
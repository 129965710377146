import React from "react";
import "../../scss/components/sections.scss";

const ItemDetailContainer = ({ topborder = false, nopadding = false, styles, somegive = false, children }) => {
  let containerClass = topborder ? "itemdetailcontainer top-border" : "itemdetailcontainer";
  containerClass = nopadding ? `${containerClass} no-padding` : containerClass;
  containerClass = somegive ? `${containerClass} somegive` : containerClass;

  return (
    <div className={containerClass} style={{ ...styles }}>
      {children}
    </div>
  );
}

export default ItemDetailContainer;

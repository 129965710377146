import React from "react";
import './route-report-item.scss';
import pdfImage from '../../../../images/icons/pdf.png';
import pdfApproveImage from '../../../../images/icons/pdf-check.png';
import { formatDateTime } from "../../../../utils/datetime/datetimeFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

const RouteReportItem = ({ report, index, activeIndex, select = () => { }, handleApproveReport = () => { }, anyApprovedReports = false }) => {
  let classToUse = "route-report-item";
  if (index === activeIndex) { classToUse += " active" };

  let ttstyle = {
    display: 'inline-block'
  };

  return (
    <div className={classToUse} onClick={() => select(report, index)}>
      <div className="route-report-row">
        <div className="route-report-item-number">
          <p>{index}.</p>
        </div>
        <div className="route-report-icon-box">
          <img src={report.approved ? pdfApproveImage : pdfImage} className="route-report-icon" alt="pdf" />
        </div>
        <div className="route-report-date">
          <p>{formatDateTime(report.createdDate)}</p>
        </div>
        {!anyApprovedReports && (
          <TooltipComponent content="Approve Report" isSticky={false} style={ttstyle} position="RightCenter">
            <div className="route-report-approve-button" onClick={(e) => {
              e.stopPropagation();
              handleApproveReport(report, index);
            }}>
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </TooltipComponent>
        )}
      </div>
    </div>
  );
}

export default RouteReportItem;

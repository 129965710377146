import React from "react";
import Select from 'react-select';

const LabeledSelect = ({ label, options, isDisabled, isLoading, isClearable, isSearchable, name, onChange, value }) => (
  <div className="labeled-input">
    {label && <label>{label}</label>}
    <Select
      className="basic-single"
      classNamePrefix="select"
      defaultValue={options[0]}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      options={options}
      onChange={onChange}
      value={value}
    />
  </div>
);

export default LabeledSelect;

import React from "react";
import { colourPicker, getSiteTypeColour } from "../../../../../utils/colour/Colours";
import { enumChecker } from "../../../../../utils/enums/Enums";
import "./site-detail.scss";
import DualColumn from "../../../../containers/DualColumn";

const SiteDetail = ({ site, siteTypes, extendedDetail = false }) => {
  const { siteName, siteCode, siteAlternateId, siteDescription, siteType, siteStatus, area, clientRegion } = site;

  const SiteInfo = ({ code, altId, desc }) => {
    return (
      <div className="site-info">
        <div className="site-field">
          <label>Code:</label>
          <span>{code}</span>
        </div>
        <div className="site-field">
          <label>Alternate ID:</label>
          <span>{altId}</span>
        </div>
        <div className="site-field">
          <label>Description:</label>
          <span>{desc}</span>
        </div>
      </div>
    );
  }

  const SiteInfoExtended = ({ status, area, region }) => {
    return (
      <div className="site-info">
        <div className="site-field">
          <label>Status:</label>
          <span>{status}</span>
        </div>
        <div className="site-field">
          <label>Area:</label>
          <span>{area}</span>
        </div>
        <div className="site-field">
          <label>Client Region:</label>
          <span>{region}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="site-detail">
      <div className="site-title">
        <h3>Site Details:</h3>
        <span className="site-name">{siteName}</span>
        <span className="site-pill" style={{ backgroundColor: colourPicker(getSiteTypeColour, siteTypes, siteType) }}>
          {enumChecker(siteType, siteTypes)}
        </span>
      </div>
      {extendedDetail ? <>
        <DualColumn
          leftChildren={<SiteInfo code={siteCode} altId={siteAlternateId} desc={siteDescription} />}
          rightChildren={<SiteInfoExtended status={siteStatus} area={area} region={clientRegion} />}
        />
      </> : <SiteInfo code={siteCode} altId={siteAlternateId} desc={siteDescription} />}
    </div>
  );
}

export default SiteDetail;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-information-tab-group button.disabled {
  cursor: not-allowed;
  color: gray; }
`, "",{"version":3,"sources":["webpack://./src/components/sections/itemdetailsections/job/JobInformation/jobinformation.scss"],"names":[],"mappings":"AAAA;EAGY,mBAAmB;EACnB,WAAW,EAAA","sourcesContent":[".job-information-tab-group {\n    button {\n        &.disabled {\n            cursor: not-allowed;\n            color: gray;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './site-item.scss';
import { enumChecker } from '../../../utils/enums/Enums';

const SiteItem = ({ site, siteStatuses, selectSite = () => { } }) => {
  const statusLabel = enumChecker(site.siteStatus, siteStatuses, () => siteStatuses.find(status => status.value === site.siteStatus));

  return (
    <div className='site-item' onClick={() => selectSite(site)}>
      <div className='site-info'>
        <div className='site-header'>
          <span className='site-name'>{site.siteName}</span>
          <span className='status-pill' data-status={statusLabel}>{statusLabel}</span>
        </div>
        <div className='site-detail'>
          <span className='site-client-name'>{site.clientName}</span>
          {/* <span className='site-code'>{`${site.siteCode} | ${site.siteAlternateId}`}</span> */}
        </div>
      </div>
    </div>
  );
}

export default SiteItem;

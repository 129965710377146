// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../images/in-app/backgrounds/common-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inspections-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .inspections-container h1 {
    padding-left: 15px;
    color: white; }
`, "",{"version":3,"sources":["webpack://./src/screens/private/she/inspections/Inspections/inspections.scss","webpack://./src/scss/mixins/_flex-layout.scss"],"names":[],"mappings":"AAEA;ECDI,aAAa;EACb,mBDCmC;ECAnC,2BDA+C;ECC/C,uBDD2D;ECM3D,WAAW;EDLX,YAAY;EAEZ,yDAA+E;EAC/E,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B,EAAA;EAP/B;IAUQ,kBAAkB;IAClB,YAAY,EAAA","sourcesContent":["@import \"../../../../../scss/main.scss\";\n\n.inspections-container {\n    @include flex-layout-full-width(row, flex-start, flex-start);\n    height: 100%;\n\n    background-image: url('../../../../../images/in-app/backgrounds/common-bg.png');\n    background-size: cover; // Make sure the image covers the entire container\n    background-repeat: no-repeat; // Prevent image from tiling\n    background-position: center; // Center the image within the element\n\n    h1 {\n        padding-left: 15px;\n        color: white;\n    }\n}","@mixin flex-layout($direction: row, $justify: flex-start, $align: flex-start) {\n    display: flex;\n    flex-direction: $direction;\n    justify-content: $justify;\n    align-items: $align;\n}\n\n@mixin flex-layout-full-width($direction: row, $justify: flex-start, $align: flex-start) {\n    @include flex-layout($direction, $justify, $align);\n    width: 100%;\n}\n\n@mixin flex-layout-custom-width($direction: row, $justify: flex-start, $align: flex-start, $width: 100%) {\n    @include flex-layout($direction, $justify, $align);\n    width: $width;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

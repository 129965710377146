import React from "react";
import TextInput from "./TextInput";
import "../../scss/components/formcomponents.scss";

const LabeledInput = ({ label, placeholder, value, onChange }) => (
  <div className="labeled-input">
    {label && <label>{label}</label>}
    <TextInput placeholder={placeholder} value={value} onChange={onChange} />
  </div>
);

export default LabeledInput;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './css/index.css';
import './scss/main.scss';
import reportWebVitals from './reportWebVitals';
import { NavigationProvider, useNavigation } from './navigation/NavigationContext';
import { AuthProvider } from './auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import { registerLicense } from '@syncfusion/ej2-base';

const MainApplicationWrapper = () => {
  const { router } = useNavigation();

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer limit={3} />
    </>
  );
};

//registerLicense("ORg4AjUWIQA/Gnt2UFhhQlJBfVldXGBWfFN0QXNfdVp3fldBcC0sT3RfQFljTHxXdkZnXXtZc31WQg==");
//registerLicense("ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd01jX31XdX1TT2dZ");
// Let us try a third time...
registerLicense("ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Udk1jWntZdH1XRmdZ"); // Version 26.x.x

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NavigationProvider>
      <AuthProvider>
        <MainApplicationWrapper />
      </AuthProvider>
    </NavigationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

class ArrayChecker {
  static arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
  
    const map = {};
  
    for (const element of arr1) {
      map[element] = (map[element] || 0) + 1;
    }
  
    for (const element of arr2) {
      if (!map[element]) return false;
      map[element]--;
    }
  
    return Object.values(map).every(count => count === 0);
  };
}

export default ArrayChecker;
import React, { useState, useEffect } from "react";
import ApiService from "../../../../../auth/ApiService";
import { enumChecker } from "../../../../../utils/enums/Enums";
import "./jobs-detail.scss";
import JobRow from "../../../../listitems/JobRow";
import JobCardRow from "../../jobcard/JobCardRow";

const JobsDetail = ({
  jobs,
  jobTypes,
  jobPriorities,
  noDelink = false,
  onDelink = () => { },
  noEdit = false,
  entityToEdit = "Entity"
}) => {
  const [siteNames, setSiteNames] = useState([]);

  const getSiteName = async (siteid) => {
    try {
      const response = await ApiService.get(`sites/${siteid}`);
      if (response?.data?.site) {
        const site = response?.data?.site;
        return `${site.siteCode ? `${site.siteCode} | ` : ""}${site.siteAlternateId ? `${site.siteAlternateId} | ` : ""}${site.siteName ?? "N/A"}`
      }
    } catch {
      return "N/A";
    }
  }

  useEffect(() => {
    console.log("Jobs: ", jobs);
    const fetchSiteNames = async () => {
      const siteNamesPromises = jobs.map((job) =>
        getSiteName(job.jobDetail.siteId)
      );
      const fetchedSiteNames = await Promise.all(siteNamesPromises);
      setSiteNames(fetchedSiteNames);
    };

    fetchSiteNames();
  }, [jobs]);

  return (
    <div className="route-jobs">
      <div className="route-jobs-title">
        <h3>Jobs Detail:</h3>
        <span className="jobs-number">{`In total ${jobs.length} job${jobs.length === 1 ? '' : 's'}`}</span>
      </div>
      <div className="route-jobs-table">
        <div className="route-jobs-header">
          <span>Site Name</span>
          <span>Job Description</span>
          <span>Job Type</span>
          <span>Priority</span>
          <div className="button-area"></div>
        </div>
        {jobs.map((job, index) => (
          <JobRow
            key={index}
            sitename={siteNames[index] || "Loading..."}
            jobdescription={job.jobDetail.jobDescription}
            jobtype={enumChecker(job.jobDetail.jobType, jobTypes, jobTypes.find(x => x.value === job.jobDetail.jobType))}
            priority={enumChecker(job.jobDetail.jobPriority, jobPriorities, jobPriorities.find(x => x.value === job.jobDetail.jobPriority))}
            noEdit={noEdit}
            entityToEdit={entityToEdit}
            editComponent={<JobCardRow jobcards={job.jobCard} />}
            noDelink={noDelink}
            onDelink={() => onDelink(job.jobDetail.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default JobsDetail;

import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faUsers } from '@fortawesome/free-solid-svg-icons';
import TopContainer from '../../../../components/containers/TopContainer';
import SubNavContainer from '../../../../components/containers/SubNavContainer';

const Clients = () => {
  return (
    <>
      <TopContainer />
      <SubNavContainer>
        <ul>
          <li><Link to={`/sterna/dashboard/clients/new`}><FontAwesomeIcon icon={faPlusSquare} className='awesome-icon'/> New Client</Link></li>
          <li><Link to={`/sterna/dashboard/clients/all`}><FontAwesomeIcon icon={faUsers} className='awesome-icon'/> Clients</Link></li>
        </ul>
      </SubNavContainer>
      <div className='operations-section'>
        <Outlet />
      </div>
    </>
  );
};

export default Clients;
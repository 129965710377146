import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll } from '@fortawesome/free-solid-svg-icons';
import SubNavContainer from '../../../../components/containers/SubNavContainer';
import SideListContainer from '../../../../components/containers/SideListContainer';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import './reports-all.scss';

const ReportsAll = () => {
  return (
    <div className='reports-all-container'>
      <SubNavContainer>
        <ul>
          <li><Link to={`/sterna/dashboard/documents/all/clientreports`}><FontAwesomeIcon icon={faScroll} className='awesome-icon'/> Reports</Link></li>
        </ul>
      </SubNavContainer>
      <SideListContainer semitransparentbg>
        
      </SideListContainer>
      <ItemDetailContainer>

      </ItemDetailContainer>
    </div>
  );
}

export default ReportsAll;
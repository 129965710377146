import React from "react";
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import JobCardRenderer from "../JobCards";
import ItemDetailContainer from "../../../../../../containers/ItemDetailContainer";
import "./tabcustomstyles.css";

const JobTabRenderer = ({ tabEntries, jobItemStatuses }) => {
  const content = (entries) => {
    return (
      <JobCardRenderer jobCardEntries={entries} jobItemStatuses={jobItemStatuses} />
    );
  }

  console.log('TabComponent: ', TabComponent);
  console.log('TabItemsDirective: ', TabItemsDirective);
  console.log('TabItemDirective: ', TabItemDirective);
  return (
    <ItemDetailContainer nopadding>
      <TabComponent heightAdjustMode="Fill" width="100%" overflowMode="Popup">
        <TabItemsDirective>
          {tabEntries.map((tabEntry, index) => (
            <TabItemDirective key={`${tabEntry.text}-${index}`} header={tabEntry} content={() => content(tabEntry.entries)} />
          ))}
        </TabItemsDirective>
      </TabComponent>
    </ItemDetailContainer>
  );
}

export default JobTabRenderer;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-base/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Share Tech', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/css/index.css"],"names":[],"mappings":"AAWA;EACE,SAAS;EACT,UAAU;EACV;;iBAEe;EACf;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE;aACW;AACb;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';  \n@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';\n@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  \n@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';  \n@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';\n@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';\n@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';\n@import \"../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css\";\n@import \"../../node_modules/@syncfusion/ej2-react-popups/styles/material.css\";\n@import '../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';\n\nbody {\n  margin: 0;\n  padding: 0;\n  /* font-family: -apple-system, BlinkMacSystemFont, 'Share Tech', 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; */\n  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow: hidden;\n  height: 100%;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n* {\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { DateTime } from "luxon";
import { enumChecker } from "../../../utils/enums/Enums";
import { getIndexOfName } from "../../../utils/colour/Colours";
import JobPriorityColours from "../../../utils/colour/JobPriorityColours";
import "./job-item.scss";

const JobItem = ({ job, jobStatuses, selectJob = () => { } , showCreateDate = false}) => {
  const isDueShortly = new Date(job.dueByDate).getTime() < (Date.now() + 24 * 60 * 60 * 1000);
  const isOverdue = new Date(job.dueByDate).getTime() < Date.now();

  let itemClass = "job-item";
  if (isOverdue) {
    itemClass = `${itemClass} overdue inside-overdue-shadow`;
  } else if (isDueShortly) {
    itemClass = `${itemClass} due-soon inside-due-shortly-shadow`;
  }

  const statusLabel = enumChecker(job.jobStatus, jobStatuses, () => jobStatuses.find(status => status.value === job.jobStatus));

  return (
    <div className={itemClass} onClick={() => selectJob(job)}>
      <div className="priority-circle" data-priority={getIndexOfName(job.jobPriority, JobPriorityColours())}></div>
      <div className="job-info">
        <div className="job-header">
          <span className="job-name">{job.jobDescription}</span>
          <span className="status-pill" data-status={statusLabel}>{statusLabel}</span>
        </div>
        <div className="job-dates">
          {showCreateDate ? <span className="create-date">{DateTime.fromISO(job.createdDate).toFormat('yyyy-MM-dd, hh:mm:ss')}</span> : <span></span>}
          {(isDueShortly || isOverdue) && (
            <span className="due-pill" data-type={isOverdue ? 'overdue' : 'due-shortly'}>
              {isOverdue ? 'Overdue' : 'Due Shortly'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobItem;

// downloadPdf.js
export const downloadPdf = (pdfLink) => {
  // Create a new anchor element
  const link = document.createElement("a");
  link.href = pdfLink;
  link.download = "report.pdf";

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically trigger a click event
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
import React from 'react';
import { Link } from 'react-router-dom';

const MainHeader = ({ active = "home", children }) => {
  return (
    <header className="full-height-section header-section">
      {/* <div className="nav-separator" />
      <nav>
        <ul>
          <li><Link to={`/`} className={active === "home" ? "active" : ""} href="#">Home</Link></li>
          <li><Link to={`/about`} className={active === "about" ? "active" : ""} href="#">About</Link></li>
          <li><Link to={`/features`} className={active === "features" ? "active" : ""} href="#">Features</Link></li>
          <li><Link to={`/screenshots`} className={active === "screenshots" ? "active" : ""} href="#">Screenshots</Link></li>
          <li><Link to={`/pricing`} className={active === "pricing" ? "active" : ""} href="#">Pricing</Link></li>
          <li><Link to={`/testimonials`} className={active === "testimonials" ? "active" : ""} href="#">Testimonials</Link></li>
          <li><Link to={`/contact`} className={active === "contact" ? "active" : ""} href="#">Contact</Link></li>
          <li><Link to={`/login`} className={active === "login" ? "link active" : "link"}>Login</Link></li>
        </ul>
      </nav> */}
      {children}
    </header>
  );
}

export default MainHeader;
import React from "react";
import "../../scss/components/formcomponents.scss";

const LabeledCheckbox = ({ label, checked, onChange = () => {} }) => {
  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label>{label}</label>
    </div>
  );
}

export default LabeledCheckbox;

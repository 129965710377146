const enumChecker = (enumvar, array, fx = () => { return array[enumvar] }) => {
  if (!enumvar || !array) return "undefined";
  if (array.length === 0) return "undefined";

  if (typeof enumvar === 'number') {
    return fx() ? fx().label : "undefined";
  }
  if (typeof enumvar === 'string') {
    return enumvar.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}

export { enumChecker };
import React, { useState, useEffect } from 'react';
import ApiService from '../../../../auth/ApiService';
import FormValidator from '../../../../utils/validation/FormValidator';
import ErrorHandling from '../../../../utils/errors/ErrorHandling';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { newRouteValidations } from '../../../../utils/validation/sets/routeValidations';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import SideFormContainer from '../../../../components/containers/SideFormContainer';
import LabeledInput from '../../../../components/formcomponents/LabeledInput';
import LabeledSelect from '../../../../components/formcomponents/LabeledSelect';
import LabeledDateTimePicker from '../../../../components/formcomponents/LabeledDateTimePicker';

import 'react-toastify/dist/ReactToastify.css';
import './routes-new.scss';

const RoutesNew = () => {
  const navigate = useNavigate();

  const [routePriorities, setRoutePriorities] = useState([{ value: "0", label: "None" }]);

  const [loadingRoutePriorities, setLoadingRoutePriorities] = useState(true);
  const [creating, setCreating] = useState(false);

  const [routeName, setRouteName] = useState('');
  const [routePriority, setRoutePriority] = useState();
  const [dueDate, setDueDate] = useState(DateTime.now().toJSDate());

  useEffect(() => {
    const fetchRoutePrioritiesData = async () => {
      try {
        setLoadingRoutePriorities(true);
        const response = await ApiService.get('enums/Route/RoutePriority');
        if (response?.data?.options) {
          setRoutePriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching route priorities: ', error);
      } finally {
        setLoadingRoutePriorities(false);
      }
    }

    fetchRoutePrioritiesData();
  }, []);

  const handleRouteNameChange = (event) => {
    setRouteName(event.target.value);
  }

  const handleRoutePriorityChange = (selectedOption) => {
    setRoutePriority(selectedOption);
  }

  const handleDueDateChange = (newDate) => {
    setDueDate(newDate);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validator = new FormValidator({ routeName, routePriority, dueDate }, newRouteValidations);

    if (validator.validate()) {
      try {
        setCreating(true);
        const data = {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          route: {
            routeName,
            dueByDate: dueDate,
            priority: routePriority.value
          }
        };
        const response = await ApiService.post('routes', data);
        if (response?.data?.routeId) {
          if (response.data.routeId == '00000000-0000-0000-0000-000000000000') {
            return ErrorHandling.toastifyError(response.data.message);
          }

          ErrorHandling.toastifySuccess("Route Created!");

          setRouteName('');
          setRoutePriority(undefined);
          setDueDate(DateTime.now().toJSDate());

          setTimeout(() => {
            navigate('/sterna/dashboard/routes/all');
          }, 5000);
        } else {
          ErrorHandling.toastifyError(response?.data?.message ?? "Unknown call error!");
        }
      } catch {
        ErrorHandling.toastifyError("Route Creation Failed!");
      } finally {
        setCreating(false);
      }
    }
  }

  return (
    <div className='routes-new-container'>
      <SideFormContainer
        formtitle="Configure New Service Path"
        icon={faTruck}
        buttonTitle="Create Route"
        loading={creating}
        onSubmit={handleSubmit}
        fancy
      >
        <LabeledInput
          label="Route Name:"
          placeholder="Route"
          value={routeName}
          onChange={handleRouteNameChange}
        />
        <LabeledSelect
          label="Priority:"
          options={routePriorities}
          isDisabled={false}
          isLoading={loadingRoutePriorities}
          isClearable={true}
          isSearchable={true}
          name="priorityselect"
          onChange={handleRoutePriorityChange}
          value={routePriority}
        />
        <LabeledDateTimePicker
          label="Due Date:"
          date={dueDate}
          onSelect={() => { }}
          onChange={handleDueDateChange}
        />
      </SideFormContainer>
      <ItemDetailContainer nopadding>

      </ItemDetailContainer>
    </div>
  );
}

export default RoutesNew;

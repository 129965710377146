import React, { useState } from "react";
import JobCardRowAdd from "../JobCardRowAdd";

const JobCardRow = ({ jobcards }) => {
  const [localList, setLocalList] = useState(jobcards);

  return (
    <div>
      {
        localList?.length > 0 ?
        <div>
          <p>{`${localList.length} job card entr${localList.length === 1 ? "y" : "ies"}`}</p>
        </div> :
        <div>
          <p>No job card entries</p>
          <JobCardRowAdd />
        </div>
      }
    </div>
  );
};

export default JobCardRow;

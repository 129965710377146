import React, { useState, useEffect } from 'react';
import './text-loader.scss';

const TextLoadingIndicator = ({ text = "Loading" }) => {
  const [ellipsis, setEllipsis] = useState('.');

  useEffect(() => {
    const timer = setInterval(() => {
      setEllipsis((prevEllipsis) => {
        switch (prevEllipsis) {
          case '.':
            return '..';
          case '..':
            return '...';
          case '...':
            return '.';
          default:
            return '.';
        }
      });
    }, 1000); // Update every second

    return () => clearInterval(timer);  // Cleanup on unmount
  }, []);

  return (
    <div className='loader-container'>
      <p>{`${ellipsis}${text}${ellipsis}`}</p>
    </div>
  );
};

export default TextLoadingIndicator;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dual-column-container {
  display: flex;
  width: 100%;
  background-color: transparent; }

.column {
  flex: 1 1;
  min-width: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/containers/DualColumn/dualcolumn.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,6BAA6B,EAAA;;AAG/B;EACE,SAAO;EACP,YAAY,EAAA","sourcesContent":[".dual-column-container {\n  display: flex;\n  width: 100%;\n  background-color: transparent;\n}\n\n.column {\n  flex: 1;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

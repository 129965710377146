import React from "react";
import JobCard from "../JobCard";

const JobCardRenderer = ({ jobCardEntries, jobItemStatuses }) => {
  if (!jobCardEntries || jobCardEntries.length === 0) {
    return <p>No Job Cards Available</p>;
  }

  return jobCardEntries.map((jobCardEntry, index) => {
    return <JobCard key={index} jobCardEntry={jobCardEntry} jobItemStatuses={jobItemStatuses} />;
  });
}

export default JobCardRenderer;
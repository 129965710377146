import PrivateRoute from '../PrivateRoute';
import Dashboard from '../../screens/private/main/Dashboard';
import Jobs from '../../screens/private/jobs/Jobs';
import Routes from '../../screens/private/routes/Routes';
import Clients from '../../screens/private/clients/Clients';
import Sites from '../../screens/private/sites/Sites';
import Reports from '../../screens/private/reports/Reports';
import JobsNew from '../../screens/private/jobs/JobsNew';
import RoutesNew from '../../screens/private/routes/RoutesNew';
import ClientsNew from '../../screens/private/clients/ClientsNew';
import SitesNew from '../../screens/private/sites/SitesNew';
import JobsOpen from '../../screens/private/jobs/JobsOpen';
import RoutesAll from '../../screens/private/routes/RoutesAll';
import ClientsAll from '../../screens/private/clients/ClientsAll';
import ReportsAll from '../../screens/private/reports/ReportsAll';
import SitesAll from '../../screens/private/sites/SitesAll';
import RoutesReleased from '../../screens/private/routes/RoutesReleased';
import RoutesPlanned from '../../screens/private/routes/RoutesPlanned';
import RoutesApproved from '../../screens/private/routes/RoutesApproved';
import Tickets from '../../screens/private/tickets/Tickets';
import TicketsOpen from '../../screens/private/tickets/TicketsOpen';
import { PublicRoutes } from './PublicRoutes';
import CompanyProfile from '../../screens/private/profile/CompanyProfile';
import UserProfile from '../../screens/private/profile/UserProfile';
import Deliveries from '../../screens/private/deliveries/Deliveries';
import Vehicles from '../../screens/private/vehicles/Vehicles';
import VehiclesAll from '../../screens/private/vehicles/VehiclesAll';
import VehiclesFines from '../../screens/private/vehicles/VehiclesFines';
import VehiclesAccidents from '../../screens/private/vehicles/VehiclesAccidents';
import VehiclesNew from '../../screens/private/vehicles/VehiclesNew';
import FuelLog from '../../screens/private/fuellog/FuelLog';
import FuelLogAll from '../../screens/private/fuellog/FuelLogAll';
import FleetMaintenance from '../../screens/private/fleetmaintenance/Maintenance';
import MaintenanceAll from '../../screens/private/fleetmaintenance/MaintenanceAll';
import FleetReports from '../../screens/private/reports/FleetReports';
import Inspections from '../../screens/private/she/inspections/Inspections';
import RiskAssessments from '../../screens/private/she/riskassessments/RiskAssessments';

export const SternaRoutes = [
  ...PublicRoutes,
  {
    path: "/sterna/dashboard",
    element: <PrivateRoute>
      <Dashboard />
    </PrivateRoute>,
    children: [
      // Jobs & Tasks
      {
        path: "/sterna/dashboard/jobs",
        element: <Jobs />,
        children: [
          {
            path: "/sterna/dashboard/jobs/open",
            element: <JobsOpen />
          },
          {
            path: "/sterna/dashboard/jobs/new",
            element: <JobsNew />
          }
        ]
      },
      {
        path: "/sterna/dashboard/tickets",
        element: <Tickets />,
        children: [
          {
            path: "/sterna/dashboard/tickets/open",
            element: <TicketsOpen />
          }
        ]
      },
      // Logistics
      {
        path: "/sterna/dashboard/routes",
        element: <Routes />,
        children: [
          {
            path: "/sterna/dashboard/routes/all",
            element: <RoutesAll />
          },
          {
            path: "/sterna/dashboard/routes/approved",
            element: <RoutesApproved />
          },
          {
            path: "/sterna/dashboard/routes/planned",
            element: <RoutesPlanned />
          },
          {
            path: "/sterna/dashboard/routes/released",
            element: <RoutesReleased />
          },
          {
            path: "/sterna/dashboard/routes/new",
            element: <RoutesNew />
          }
        ]
      },
      {
        path: "/sterna/dashboard/deliveries",
        element: <Deliveries />
      },
      // Fleet Management
      {
        path: "/sterna/dashboard/vehicles",
        element: <Vehicles />,
        children: [
          {
            path: "/sterna/dashboard/vehicles/all",
            element: <VehiclesAll />
          },
          {
            path: "/sterna/dashboard/vehicles/new",
            element: <VehiclesNew />
          },
          {
            path: "/sterna/dashboard/vehicles/fines",
            element: <VehiclesFines />
          },
          {
            path: "/sterna/dashboard/vehicles/accidents",
            element: <VehiclesAccidents />
          }
        ]
      },
      {
        path: "/sterna/dashboard/fleetfuel",
        element: <FuelLog />,
        children: [
          {
            path: "/sterna/dashboard/fleetfuel/all",
            element: <FuelLogAll />
          }
        ]
      },
      {
        path: "/sterna/dashboard/fleetmaintenance",
        element: <FleetMaintenance />,
        children: [
          {
            path: "/sterna/dashboard/fleetmaintenance/all",
            element: <MaintenanceAll />
          }
        ]
      },
      {
        path: "/sterna/dashboard/fleetreports",
        element: <FleetReports />
      },
      // Management
      {
        path: "/sterna/dashboard/companyprofile",
        element: <CompanyProfile />
      },
      {
        path: "/sterna/dashboard/clients",
        element: <Clients />,
        children: [
          {
            path: "/sterna/dashboard/clients/all",
            element: <ClientsAll />
          },
          {
            path: "/sterna/dashboard/clients/new",
            element: <ClientsNew />
          }
        ]
      },
      {
        path: "/sterna/dashboard/sites",
        element: <Sites />,
        children: [
          {
            path: "/sterna/dashboard/sites/all",
            element: <SitesAll />
          },
          {
            path: "/sterna/dashboard/sites/new",
            element: <SitesNew />
          }
        ]
      },
      {
        path: "/sterna/dashboard/userprofile",
        element: <UserProfile />
      },
      // Health & Safety
      {
        path: "/sterna/dashboard/sheinspections",
        element: <Inspections />
      },
      {
        path: "/sterna/dashboard/sheriskassessments",
        element: <RiskAssessments />
      },
      // Directory
      {
        path: "/sterna/dashboard/documents",
        element: <Reports />,
        children: [
          {
            path: "/sterna/dashboard/documents/all",
            element: <ReportsAll />
          }
        ]
      },
      
    ]
  }
];
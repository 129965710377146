import React, { useState } from 'react';
import SideListContainer from '../../../../components/containers/SideListContainer';
import './clients-all.scss';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';

const ClientsAll = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div className='clients-all-container'>
      <SideListContainer loading={loading} semitransparentbg>
        
      </SideListContainer>
      <ItemDetailContainer>
        
      </ItemDetailContainer>
    </div>
  );
}

export default ClientsAll;
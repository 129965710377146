import JobItemStatusColours from "./JobItemStatusColours";
import JobPriorityColours from "./JobPriorityColours";
import JobTypeColours from "./JobTypeColours";
import RoutePriorityColours from "./RoutePriorityColours";
import RouteStatusColours from "./RouteStatusColours";
import SiteTypeColours from "./SiteTypeColours";
import TicketPriorityColours from "./TicketPriorityColours";
import ClientTypeColours from "./ClientTypeColours";

const colourPicker = (fx = () => {}, array, variable) => {
  if (!variable || !array) return "#D3D3D3";
  if (array.length === 0) return "#D3D3D3";

  if (typeof variable === 'number') {
    return array[variable] ? fx(array[variable].label) : "#D3D3D3";
  }
  if (typeof variable === 'string') {
    return fx(variable.replace(/([a-z])([A-Z])/g, '$1 $2'));
  }
}

const getIndexOfName = (variable, array) => {
  let index = 0;
  for (const key in array) {
    if (key === variable) {
      return index;
    }
    index++;
  }
  return -1;
}

const getSiteTypeColour = (type) => {
  const colours = SiteTypeColours();
  return colours[type] || "#D3D3D3";
}

const getJobTypeColour = (type) => {
  const colours = JobTypeColours();
  return colours[type] || "#D3D3D3"; // Light Gray (default for unknown)
}

const getJobPriorityColour = (priority) => {
  const colours = JobPriorityColours();
  return colours[priority] || "#D3D3D3"; // Light Gray (default for unknown)
}

const getJobItemStatusColour = (status) => {
  const colours = JobItemStatusColours();
  return colours[status] || "#D3D3D3";
}

const getRoutePriorityColour = (priority) => {
  const colours = RoutePriorityColours();
  return colours[priority] || "#D3D3D3"; // Light Gray (default for unknown)
}

const getRouteStatusColour = (status) => {
  const colours = RouteStatusColours();
  return colours[status] || "#D3D3D3"; // Light Gray (default for unknown)
}

const getTicketPriorityColour = (priority) => {
  const colours = TicketPriorityColours();
  return colours[priority] || "#D3D3D3"; // Light Gray (default for unknown)
}

const getClientTypeColour = (type) => {
  const colours = ClientTypeColours();
  return colours[type] || "#D3D3D3";
}

export { colourPicker,
  getIndexOfName,
  getSiteTypeColour, 
  getJobTypeColour, 
  getJobPriorityColour, 
  getJobItemStatusColour,
  getRoutePriorityColour, 
  getRouteStatusColour,
  getTicketPriorityColour,
  getClientTypeColour
};


import React, { useState, useEffect } from "react";
import ApiService from "../../auth/ApiService";
import { DateTime } from "luxon";
import InputContainer from "./InputContainer";
import LabeledFileInput from "../formcomponents/LabeledFileInput";
import ErrorHandling from "../../utils/errors/ErrorHandling";
import ArrayChecker from "../../utils/arrays/ArrayChecker";
import "../../scss/components/sections.scss";

const PhotosUploadInputType = ({ jobcard, jobItemStatuses }) => {
  const [fileBytes, setFileBytes] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [saving, setSaving] = useState(false);
  const [maxPhotos, setMaxPhotos] = useState(5);

  const [prevImageUrls, setPrevImageUrls] = useState([]);

  const [hasDifferences, setHasDifferences] = useState(false);

  useEffect(() => {
    const fetchJobItems = async () => {
      try {
        const response = await ApiService.get('JobItems');
        if (response?.data?.jobItems) {
          const jobItem = response.data.jobItems.find(ji => ji.id === jobcard.jobItem.id);
          if (jobItem) {
            setMaxPhotos(jobItem.maximumNumberOfPhotos ?? 1);
          }
        }
      } catch (error) {
        console.error('Error fetching routes: ', error);
      }
    }

    fetchJobItems();
  }, []);

  useEffect(() => {
    if (jobcard?.inputTypeSpecification?.photographs) {
      setPrevImageUrls(jobcard.inputTypeSpecification.photographs.map((photograph) => photograph.photoUrl));
      setImageUrls(jobcard.inputTypeSpecification.photographs.map((photograph) => photograph.photoUrl));
      console.log(prevImageUrls, imageUrls);
    }
  }, [jobcard]);

  useEffect(() => {
    if (!prevImageUrls) {
      setHasDifferences(true);
    } else if (!ArrayChecker.arraysAreEqual(imageUrls, prevImageUrls)) {
      setHasDifferences(true);
    } else {
      setHasDifferences(false);
    }
  }, [imageUrls]);

  useEffect(() => {
    if (fileBytes.length > 0) {
      const newDataUrls = fileBytes.map((fB) => `data:image/jpeg;base64,${fB}`);
      setImageUrls(newDataUrls);
    }
  }, [fileBytes]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    if (files.length > maxPhotos) {
      return ErrorHandling.toastifyError(`You can only upload up to ${maxPhotos} files.`);
    }

    Promise.all(files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
        };
        reader.readAsDataURL(file);
      });
    }))
      .then((base64Strings) => {
        setFileBytes(base64Strings);
      });
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    if (jobcard) {
      try {
        setSaving(true);
        const photosArray = fileBytes.map(fB => {
          return {
            media: {
              mediaDate: DateTime.now().toJSDate(),
              mediaType: "Image",
              fileBytes: fB
            }
          }
        });
        const response = await ApiService.patch('JobCards', {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          id: jobcard.id,
          inputTypeSpecification: {
            id: jobcard.inputTypeSpecification.id,
            inputType: jobcard.inputTypeSpecification.inputType,
            photographs: photosArray
          }
        });
        if (!response?.data?.jobCard) return ErrorHandling.toastifyError("Job Card Item Update Failed!");
        setPrevImageUrls(imageUrls);
        setHasDifferences(false);
        return ErrorHandling.toastifySuccess("Job Card Item Updated!");
      } catch (error) {
        ErrorHandling.toastifyError("Update Failed due to Unexpected Error!");
      } finally {
        setSaving(false);
      }
    }
  }

  const discardChanges = () => {
    setImageUrls(prevImageUrls);
  }

  return (
    <InputContainer jobcard={jobcard} jobItemStatuses={jobItemStatuses} onSubmit={saveChanges} loading={saving} hasDifferences={hasDifferences} onUndo={discardChanges}>
      <LabeledFileInput label="Select Images:" fileChange={handleFileChange} multiple />
      {imageUrls ? imageUrls.map((url, index) => (
        <img key={index} src={url} alt={`Preview ${index + 1}`} className="image-preview" />
      )) : null}
    </InputContainer>
  );
}

export default PhotosUploadInputType;

import React from "react";
import PhotoUploadInputType from '../../../../../../jobcardinputtypes/PhotoUploadInputType';
import PhotosUploadInputType from '../../../../../../jobcardinputtypes/PhotosUploadInputType';
import CheckAndUploadInputType from '../../../../../../jobcardinputtypes/CheckAndUploadInputType';
import TextInputType from '../../../../../../jobcardinputtypes/TextInputType';
import TextAndUploadInputType from '../../../../../../jobcardinputtypes/TextAndUploadInputType';
import CheckOneInputType from '../../../../../../jobcardinputtypes/CheckOneInputType';

const JobCard = ({ jobCardEntry, jobItemStatuses }) => {
  if (!jobCardEntry?.inputTypeSpecification?.inputType) return <div></div>
  switch (jobCardEntry.inputTypeSpecification.inputType) {
    case 0: // Text
      return <TextInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case "Text": // Text
      return <TextInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case 1: // CheckOne
      return <CheckOneInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case "CheckOne": // CheckOne
      return <CheckOneInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case 2: // CheckMany
      return <div></div>
    case "CheckMany": // CheckMany
      return <div></div>
    case 3: // PhotoUpload
      return <PhotoUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case "PhotoUpload": // PhotoUpload
      return <PhotoUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case 4: // PhotosUpload
      return <PhotosUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case "PhotosUpload": // PhotosUpload
      return <PhotosUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case 5: // CheckAndUpload
      return <CheckAndUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case "CheckAndUpload": // CheckAndUpload
      return <CheckAndUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case 6: // TextAndUpload
      return <TextAndUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    case "TextAndUpload": // TextAndUpload
      return <TextAndUploadInputType jobcard={jobCardEntry} jobItemStatuses={jobItemStatuses} />
    default:
      return <div></div>
  }
}

export default JobCard;
import Home from '../../screens/public/Home';
import ErrorPage from '../ErrorPage';
import Login from '../../screens/public/Login';
import About from '../../screens/public/About';
import Features from '../../screens/public/Features';
import Screenshots from '../../screens/public/Screenshots';
import Pricing from '../../screens/public/Pricing';
import Testimonials from '../../screens/public/Testimonials';
import Contact from '../../screens/public/Contact';
import PrivacyPolicy from '../../screens/public/PrivacyPolicy';
import TermsConditions from '../../screens/public/TermsConditions';
import ForgotPassword from '../../screens/public/ForgotPassword';

export const PublicRoutes = [
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />
  },
  // {
  //   path: "/about",
  //   element: <About />
  // },
  // {
  //   path: "/features",
  //   element: <Features />
  // },
  // {
  //   path: "/screenshots",
  //   element: <Screenshots />
  // },
  // {
  //   path: "/pricing",
  //   element: <Pricing />
  // },
  // {
  //   path: "/testimonials",
  //   element: <Testimonials />
  // },
  // {
  //   path: "/contact",
  //   element: <Contact />
  // },
  // {
  //   path: "/login",
  //   element: <Login />
  // },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />
  },
  {
    path: "/tnc",
    element: <TermsConditions />
  }
];
import React, { useState, useEffect } from "react";
import './loading-overlay.scss';

const LoadingOverlay = ({ isLoading }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 500);  // for smooth transition
    }
  }, [isLoading]);

  return (
    <div className={`loading-overlay ${visible ? 'visible' : ''}`}>
      <div className="loader"></div>
    </div>
  );
};

export default LoadingOverlay;

import React from 'react';

const SubNavContainer = ({ nobg = false, children, somegive = false }) => {
  let containerClass = nobg ? "subnavcontainer no-bg" : "subnavcontainer";
  containerClass = somegive ? `${containerClass} somegive` : containerClass;

  return (
    <div className={containerClass}>
      {children}
    </div>
  );
}

export default SubNavContainer;

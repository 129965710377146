import React, { useState, useEffect } from "react";
import ItemDetailContainer from "../../../../../../containers/ItemDetailContainer";
import JobDetail from "../../../JobDetail";
import JobCardRenderer from "../JobCards";
import ReportSelectionViewer from "../../../../report/ReportSelectionViewer";
import ApiService from "../../../../../../../auth/ApiService";
import JobTabRenderer from "../JobTabRenderer";

const JobDetails = ({ job, scaffold, jobTypes, jobPriorities, jobItemStatuses, reports, tab, triggerUpdate }) => {
  const [entries, setEntries] = useState([]);
  const [sidetabs, setSidetabs] = useState([]);
  const [useNewLayout, setUseNewLayout] = useState(true);

  useEffect(() => {
    const fetchGroup = async (jobItemId) => {
      const response = await ApiService.get(`jobItems/jobitemgroup/${jobItemId}`);
      if (response?.data?.message === "Success") {
        return response.data.jobItemGroup;
      }
      return { name: "Main" };
    }

    const fetchEntries = async () => {
      if (scaffold?.jobCardEntries) {
        const sortedJobCardEntries = scaffold.jobCardEntries.sort((a, b) => a.Sequence - b.Sequence);
        
        const promises = sortedJobCardEntries.map(async entry => {
          const group = await fetchGroup(entry.jobItemId);
          return { ...entry, group };
        });

        const results = await Promise.all(promises);
        setEntries(results);
      } else {
        setEntries([]);
      }
    }

    fetchEntries();
  }, [scaffold]);

  useEffect(() => {
    if (entries.length === 0) {
      setSidetabs([]);
      return;
    }

    const groupEntriesByGroupName = () => {
      const groupedEntries = entries.reduce((acc, entry) => {
        const groupName = entry.group.name;
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(entry);
        return acc;
      }, {});

      const sidetabsArray = Object.keys(groupedEntries)
        .map(groupName => ({
          text: groupName,
          entries: groupedEntries[groupName].sort((a, b) => a.Sequence - b.Sequence)
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

      setSidetabs(sidetabsArray);
    }

    groupEntriesByGroupName();
  }, [entries]);

  switch (tab) {
    case 1:
      return (
        <ItemDetailContainer styles={{ overflowX: "hidden", overflowY: "auto", border: "1px solid #ccc" }}>
          <JobDetail job={job} jobTypes={jobTypes} jobPriorities={jobPriorities} />
        </ItemDetailContainer>
      );
    case 2:
      if (useNewLayout) {
        return (
          <ItemDetailContainer styles={{ overflowX: "hidden", overflowY: "auto", border: "1px solid #ccc" }}>
            <JobTabRenderer jobItemStatuses={jobItemStatuses} tabEntries={sidetabs} />
          </ItemDetailContainer>
        );
      } else {
        return (
          <ItemDetailContainer styles={{ overflowX: "hidden", overflowY: "auto", border: "1px solid #ccc" }}>
            <JobCardRenderer jobCardEntries={entries} jobItemStatuses={jobItemStatuses} />
          </ItemDetailContainer>
        );
      }
    case 3:
      return (
        <ItemDetailContainer nopadding styles={{ overflowX: "hidden", overflowY: "auto", border: "1px solid #ccc" }}>
          <ReportSelectionViewer reports={reports} triggerUpdate={triggerUpdate} />
        </ItemDetailContainer>
      );
    default:
      return (
        <div>
          <p>No Tab</p>
        </div>
      );
  }
}

export default JobDetails;
import React, { useState, useEffect } from "react";
import ApiService from "../../auth/ApiService";
import InputContainer from "./InputContainer";
import ErrorHandling from "../../utils/errors/ErrorHandling";
import LabeledCheckbox from "../formcomponents/LabeledCheckbox";
import "../../scss/components/sections.scss";

const CheckOneInputType = ({ jobcard, jobItemStatuses }) => {
  const [checked, setChecked] = useState();
  const [saving, setSaving] = useState(false);

  const [prevChecked, setPrevChecked] = useState();

  const [hasDifferences, setHasDifferences] = useState(false);

  useEffect(() => {
    if (jobcard?.inputTypeSpecification) {
      setPrevChecked(jobcard.inputTypeSpecification.isChecked);
      setChecked(jobcard.inputTypeSpecification.isChecked);
    }
  }, [jobcard]);

  useEffect(() => {
    if (checked !== prevChecked) {
      setHasDifferences(true);
    } else {
      setHasDifferences(false);
    }
  }, [checked]);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    if (jobcard) {
      try {
        setSaving(true);
        const response = await ApiService.patch('JobCards', {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          id: jobcard.id,
          inputTypeSpecification: {
            id: jobcard.inputTypeSpecification.id,
            inputType: jobcard.inputTypeSpecification.inputType,
            isChecked: !!checked
          }
        });

        if (!response?.data?.jobCard) return ErrorHandling.toastifyError("Job Card Item Update Failed!");
        setPrevChecked(checked);
        setHasDifferences(false);
        return ErrorHandling.toastifySuccess("Job Card Item Updated!");
      } catch (error) {
        ErrorHandling.toastifyError("Update Failed due to Unexpected Error!");
      } finally {
        setSaving(false);
      }
    }
  }

  const discardChanges = () => {
    setChecked(prevChecked ?? false);
  }

  return (
    <InputContainer jobcard={jobcard} jobItemStatuses={jobItemStatuses} onSubmit={saveChanges} loading={saving} hasDifferences={hasDifferences} onUndo={discardChanges}>
      <LabeledCheckbox label="Confirmed" checked={checked} onChange={handleCheckChange} />
    </InputContainer>
  );
}

export default CheckOneInputType;

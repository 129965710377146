import { toast } from 'react-toastify';

class ErrorHandling {
  static toastifySuccess(successText) {
    toast.success(successText, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      icon: "🎉"
    });
  }

  static toastifyError(errorText) {
    toast.error(errorText, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      icon: "🤔"
    });
  }
}

export default ErrorHandling;
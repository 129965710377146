import React, { useState, useEffect } from "react";
import ApiService from "../../../../../auth/ApiService";
import ErrorHandling from "../../../../../utils/errors/ErrorHandling";
import { useAuth } from "../../../../../auth/AuthContext";
import { faCheckCircle, faClock, faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import SideListContainer from "../../../../containers/SideListContainer";
import JobItem from "../../../../listitems/JobItem";
import ItemDetailContainer from "../../../../containers/ItemDetailContainer";
import SubNavContainer from "../../../../containers/SubNavContainer";
import JobInformation from "../JobInformation";
import SimpleButton from "../../../../buttons/SimpleButton";
import "./jobs-detail-expanded.scss";
import LoadingOverlay from "../../../../widgets/FullPageLoader";
import { downloadPdf } from "./downloadPdf";
import { fetchReports } from "./fetchData";

const JobsDetailExpanded = ({ jobs, jobTypes, loading }) => {
  const { sternaAuth } = useAuth();

  const [companyId, setCompanyId] = useState();
  const [jobStatuses, setJobStatuses] = useState([]);
  const [jobPriorities, setJobPriorities] = useState();
  const [jobItemStatuses, setJobItemStatuses] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [jobScaffold, setJobScaffold] = useState();
  const [loadingScaffold, setLoadingScaffold] = useState(false);

  const [canComplete, setCanComplete] = useState(false);
  const [canDefer, setCanDefer] = useState(false);
  const [canCancel, setCanCancel] = useState(false);
  const [canGenerateReport, setCanGenerateReport] = useState(false);

  const [generatingReport, setGeneratingReport] = useState(false);
  const [reportGenCount, setReportGenCount] = useState(0);
  const [reports, setReports] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // SideListContainer visiblity:
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);
    console.log('Claims: ', claims);

    const fetchJobStatuses = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobStatus');
        if (response?.data?.options) {
          setJobStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job types: ', error);
      }
    }

    const fetchJobPriorities = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobPriority');
        if (response?.data?.options) {
          setJobPriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job priorities: ', error);
      }
    }

    const fetchJobItemStatuses = async () => {
      try {
        const response = await ApiService.get('enums/JobCard/JobItemStatus');
        if (response?.data?.options) {
          setJobItemStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job item statuses: ', error);
      }
    }

    const fetchCompany = async () => {
      try {
        const response = await ApiService.get(`companies/bu-user/${claims.UserId ?? '0'}`);
        if (response?.data?.message === 'Success') {
          setCompanyId(response.data.company?.id);
        }
      } catch (error) {
        console.error('Failed to fetch company for user with id: ', claims.UserId, ' | reason: ', error);
      }
    }

    fetchJobStatuses();
    fetchJobPriorities();
    fetchJobItemStatuses();
    fetchCompany();

    // Set default selected job:
    if (jobs && jobs.length > 0) {
      setSelectedJob(jobs[0])
    }
  }, []);

  useEffect(() => {
    const fetchScaffold = async () => {
      const response = await ApiService.get(`jobcards/scaffold/job-id/${selectedJob.id}/template/${getTemplateIds(companyId).jobCardTemplateId}`);
      if (response?.data?.message === 'Success') {
        console.log("Job Scaffold: ", response.data.jobCardScaffold);
        setJobScaffold(response.data.jobCardScaffold);
      } else {
        ErrorHandling.toastifyError(response.data.message ?? "Error loading Scaffold!");
      }
    };

    if (selectedJob) {
      try {
        setLoadingScaffold(true);
        fetchScaffold();
        fetchReports(selectedJob.id, setReports);
        // setCanGenerateReport(true);
      } catch (error) {
        console.error('Error getting job scaffold: ', error);
        setCanGenerateReport(false);
      } finally {
        setLoadingScaffold(false);
      }
    } else {
      setCanGenerateReport(false);
    }
  }, [selectedJob]);

  useEffect(() => {
    setSelectedJob();
    setJobScaffold();
  }, [jobs]);

  useEffect(() => {
    console.log('Reports: ', reports);
    if (reports.find(x => x.approved === true)) {
      setCanGenerateReport(false);
    } else {
      setCanGenerateReport(true);
    }
  }, [reports]);

  useEffect(() => {
    if (selectedJob) {
      fetchReports(selectedJob.id, setReports);
    }
  }, [refreshTrigger]);

  const triggerUpdate = () => {
    setRefreshTrigger(prev => prev + 1);
  }

  const toggleSidebar = () => {
    setIsVisible(!isVisible);
  }

  const completeJob = () => {
    console.log("Complete job");
  }

  const deferJob = () => {
    console.log("Defer job");
  }

  const cancelJob = () => {
    console.log("Cancel job");
  }

  // We use this function as a workaround for now.
  // Later we must fetch the templates via another way!
  const getTemplateIds = (cId) => {
    switch (cId) {
      case "78d352e5-def2-4020-9ce6-4c0cc2d664bb":    // Mkateko
        return { reportTemplateId: "61CB4098-35E5-4F56-9624-81E44FFB9E50", jobCardTemplateId: "40990662-a384-4c9f-983e-bf67d0e360b4" };
      case "f9864b94-bb29-40aa-a954-924660bb55d1":    // Solar and Panel Cleaning Solutions
        return { reportTemplateId: "f408978d-8dc2-4eae-8890-0ad15661c84e", jobCardTemplateId: "35523292-1b8e-48b3-994b-7cf727f97750" };
      default:                                        // Any other company
        return { reportTemplateId: "", jobCardTemplateId: "" };
    }
  }

  const generateReport = async () => {
    try {
      setGeneratingReport(true);
      if (selectedJob && jobScaffold && !loadingScaffold && companyId) {
        const templateId = getTemplateIds(companyId).reportTemplateId;
        const response = await ApiService.post('Reports/job', {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          jobId: jobScaffold.jobId,
          reportTemplateId: templateId, // For now, this is hardcoded, but later we need to fetch this.
          jobCardTemplateId: jobScaffold.templateId
        });

        if (response?.data?.reportResult?.link) {
          await fetchReports(selectedJob.id, setReports);
          return ErrorHandling.toastifySuccess("Report has been generated!");
          // downloadPdf(response.data.reportResult.link);
          // return ErrorHandling.toastifySuccess("Report has been downloaded!");
        }

        if (response?.data?.message) {
          return ErrorHandling.toastifyError(response.data.message);
        }

        return ErrorHandling.toastifyError("Report failed to generate!");
      }
    } catch (error) {
      // Log error here...
      return ErrorHandling.toastifyError("Exception was thrown while generating report!");
    } finally {
      setGeneratingReport(false);
    }
  }

  return (
    <div className="route-jobs-expanded">
      <LoadingOverlay isLoading={generatingReport || loadingScaffold} />
      <h3>Jobs:</h3>
      <div style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%", overflow: "hidden" }}>
        <SideListContainer loading={loading} nobg={true} topborder={true} canminimize isVisible={isVisible} toggleSidebar={toggleSidebar}>
          {jobs.map((job, index) => (
            <JobItem key={index} job={job.jobDetail} jobStatuses={jobStatuses} selectJob={setSelectedJob} />
          ))}
        </SideListContainer>
        <ItemDetailContainer topborder nopadding styles={{ backgroundColor: "#FAFAFA" }}>
          <SubNavContainer nobg={true} somegive>
            <SimpleButton title="Complete Job" icon={faCheckCircle} disabled={!canComplete || generatingReport} onClick={() => { }} />
            <SimpleButton title="Defer Job" icon={faClock} disabled={!canDefer || generatingReport} onClick={() => { }} />
            <SimpleButton title="Cancel Job" icon={faTimes} disabled={!canCancel || generatingReport} onClick={() => { }} />
            <SimpleButton title="Generate Report" icon={faDownload} disabled={!canGenerateReport || generatingReport} onClick={generateReport} />
          </SubNavContainer>
          <JobInformation
            scaffoldLoading={loadingScaffold}
            scaffold={jobScaffold}
            job={selectedJob}
            jobTypes={jobTypes}
            jobPriorities={jobPriorities}
            jobItemStatuses={jobItemStatuses}
            reports={reports}
            triggerUpdate={triggerUpdate}
          />
        </ItemDetailContainer>
      </div>
    </div>
  );
}

export default JobsDetailExpanded;

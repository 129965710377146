// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .e-tab {
    border: 5px solid rgb(173, 255, 47);
} */

/* .e-tab .e-tab-header .e-toolbar-items {
    width: 90%;
} */

/* .e-tab .e-tab-header {
    width: 90%;
} */

.e-indicator {
    background: #4F8A9B !important;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #333;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background: #fafafa !important;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text {
    color: #7BBFCE !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active {
    color: #4F8A9B !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
    color: #4F8A9B !important;
}

.e-tab .e-content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/components/sections/itemdetailsections/job/JobInformation/Renderers/JobTabRenderer/tabcustomstyles.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;;GAEG;;AAEH;;GAEG;;AAEH;IACI,8BAA8B;AAClC;;AAEA;IACI;;cAEU;IACV,WAAW;AACf;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["/* .e-tab {\n    border: 5px solid rgb(173, 255, 47);\n} */\n\n/* .e-tab .e-tab-header .e-toolbar-items {\n    width: 90%;\n} */\n\n/* .e-tab .e-tab-header {\n    width: 90%;\n} */\n\n.e-indicator {\n    background: #4F8A9B !important;\n}\n\n.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {\n    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n    color: #333;\n}\n\n.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {\n    background: #fafafa !important;\n}\n\n.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text {\n    color: #7BBFCE !important;\n}\n\n.e-tab .e-tab-header .e-toolbar-item.e-active {\n    color: #4F8A9B !important;\n}\n\n.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,\n.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {\n    color: #4F8A9B !important;\n}\n\n.e-tab .e-content {\n    height: 100%;\n    width: 100%;\n    overflow-x: hidden;\n    overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import "../../../../../scss/components/buttons.scss";
import "./route-action.scss";

const RouteAction = ({ action = "Action", description = "No description...", canPerformAction = false, onSubmit = () => { } }) => {
  const buttonClass = canPerformAction ? "create-button" : "create-button disabled";

  return (
    <div className="route-action">
      <div className="action-block">
        <h3>{description}</h3>
        <div className="fill">
        </div>
        <div className="button-block">
          <button className={buttonClass} type="button" disabled={!canPerformAction} onClick={onSubmit}>{action}</button>
        </div>
      </div>
    </div>
  );
}

export default RouteAction;

import ApiService from "../../../../../auth/ApiService";

export const fetchReports = async (jobId, setReports = () => {}) => {
  try {
    const response = await ApiService.get(`reports/${jobId}`);
    if (response?.data?.message === 'Success') {
      const sortedReports = (response.data.reports ?? []).sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      );      
      setReports(sortedReports);
    }
  } catch (error) {
    console.error(error);
  }
}
import React, { useState, useEffect } from "react";
import ApiService from "../../auth/ApiService";
import InputContainer from "./InputContainer";
import LabeledMultilineInput from "../formcomponents/LabeledMultilineInput";
import ErrorHandling from "../../utils/errors/ErrorHandling";
import "../../scss/components/sections.scss";

const TextInputType = ({ jobcard, jobItemStatuses }) => {
  const [text, setText] = useState();
  const [saving, setSaving] = useState(false);

  const [prevText, setPrevText] = useState();

  const [hasDifferences, setHasDifferences] = useState(false);

  useEffect(() => {
    if (jobcard?.inputTypeSpecification?.text) {
      setPrevText(jobcard?.inputTypeSpecification?.text);
      setText(jobcard?.inputTypeSpecification?.text);
    }
  }, [jobcard]);

  useEffect(() => {
    if (text !== prevText) {
      setHasDifferences(true);
    } else {
      setHasDifferences(false);
    }
  }, [text]);

  const textChangeHandler = (event) => {
    setText(event.target.value);
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    if (jobcard) {
      try {
        setSaving(true);
        const response = await ApiService.patch('JobCards', {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          id: jobcard.id,
          inputTypeSpecification: {
            id: jobcard.inputTypeSpecification.id,
            text,
            inputType: jobcard.inputTypeSpecification.inputType
          }
        });

        if (!response?.data?.jobCard) return ErrorHandling.toastifyError("Job Card Item Update Failed!");
        setPrevText(text);
        setHasDifferences(false);
        return ErrorHandling.toastifySuccess("Job Card Item Updated!");
      } catch (error) {
        ErrorHandling.toastifyError("Update Failed due to Unexpected Error!");
      } finally {
        setSaving(false);
      }
    }
  }

  const discardChanges = () => {
    setText(prevText);
  }

  return (
    <InputContainer jobcard={jobcard} jobItemStatuses={jobItemStatuses} onSubmit={saveChanges} loading={saving} hasDifferences={hasDifferences} onUndo={discardChanges}>
      <LabeledMultilineInput label="Text Value:" placeholder="Comments..." value={text} onChange={textChangeHandler} />
    </InputContainer>
  );
}

export default TextInputType;

import React from "react";
import '../../scss/components/formcomponents.scss';

const MultilineInput = ({ placeholder, value, onChange }) => (
  <textarea
    className="multiline-input"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

export default MultilineInput;

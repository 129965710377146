import React from "react";
import MultilineInput from "./MultilineInput";
import "../../scss/components/formcomponents.scss";

const LabeledMultilineInput = ({ label, placeholder, value, onChange }) => (
  <div className="labeled-input">
    {label && <label>{label}</label>}
    <MultilineInput placeholder={placeholder} value={value} onChange={onChange} />
  </div>
);

export default LabeledMultilineInput;

import React from "react";

const FileInput = ({ fileChange = () => {}, multiple = false }) => {
  return (
    <input
      type="file"
      accept="image/*"
      onChange={fileChange}
      className="text-input"
      multiple={multiple}
    />
  );
}

export default FileInput;

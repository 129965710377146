import React, { useState } from "react";
import SideListContainer from "../../../../components/containers/SideListContainer";
import ItemDetailContainer from "../../../../components/containers/ItemDetailContainer";
import './vehicles-accidents.scss';

const VehiclesAccidents = () => {
  const [loading, setLoading] = useState(false);
  
  return (
    <div className='vehicles-accidents-container'>
      <SideListContainer loading={loading} semitransparentbg>
        {/* {routes.length > 0 ? routes.map((route, index) => (
          <RouteItem key={index} route={route} routeStatuses={routeStatuses} selectRoute={selectRoute} />
        )) : <NoItem title="📭 No Open Routes" />} */}
      </SideListContainer>
      <ItemDetailContainer>
        {/* {!routeDetail ? <p>No Vehicle Selected</p> : <>
          <TitleSection lighttitle title={routeDetail.routeName} icon={faTruck} deleteMethod={deleteRoute} canDelete={routeDetail.jobs.length === 0}>
            <SimpleButton title="Approve Route" icon={faStamp} disabled={!canApprove} onClick={approveRoute} />
            <SimpleButton title="Default Release" icon={faRocket} disabled={!canRelease} onClick={defaultRelease} />
          </TitleSection>
          {!routeDetail ? null : <RouteDetail route={routeDetail} routeStatuses={routeStatuses} routePriorities={routePriorities} />}
          {!routeDetail.jobs ? null : <JobsDetail jobs={routeDetail.jobs} jobTypes={jobTypes} jobPriorities={jobPriorities} onDelink={delinkJobFromRoute} noEdit />}
        </>} */}
      </ItemDetailContainer>
    </div>
  );
}

export default VehiclesAccidents;

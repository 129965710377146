import React from "react";
import "./no-item.scss";

const NoItem = ({ title = "N/A" }) => {
  return (
    <div className="no-item">
      <div className="no-item-info">
        <div className="no-item-header">
          <span className="no-item-title">{title}</span>
        </div>
      </div>
    </div>
  );
}

export default NoItem;
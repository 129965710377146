import React from "react";
import FileInput from "./FileInput";

const LabeledFileInput = ({ label, fileChange = () => {}, multiple = false }) => {
  return (
    <div className="labeled-input">
      {label && <label>{label}</label>}
      <FileInput fileChange={fileChange} multiple={multiple} />
    </div>
  );
}

export default LabeledFileInput;

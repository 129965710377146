import React from "react";
import { enumChecker } from "../../../../../utils/enums/Enums";
import { colourPicker, getClientTypeColour } from "../../../../../utils/colour/Colours";
import "./client-detail.scss";

const ClientDetail = ({ clientName, clientTypes, clientType }) => {
  return (
    <div className="client-detail">
      <div className="client-title">
        <h3>Client Details:</h3>
        <span className="client-name">{clientName}</span>
        <span className="client-pill" style={{ backgroundColor: colourPicker(getClientTypeColour, clientTypes, clientType) }}>
          {enumChecker(clientType, clientTypes)}
        </span>
      </div>
    </div>
  );
}

export default ClientDetail;

import React from "react";
import RouteAction from "../RouteAction";

const RouteRelease = ({ canRelease = false, onSubmit = () => { } }) => (
  <RouteAction
    action="📤 Release"
    description="Release Route"
    canPerformAction={canRelease}
    onSubmit={onSubmit}
  />
);

export default RouteRelease;

import React from "react";
import { colourPicker, getJobTypeColour, getJobPriorityColour } from "../../../../../utils/colour/Colours";
import { enumChecker } from "../../../../../utils/enums/Enums";
import { DateTime } from "luxon";
import "./job-detail.scss";

const JobDetail = ({ job, jobTypes, jobPriorities }) => {
  const { jobDescription, createdDate, dueByDate, notes, jobType, jobPriority } = job;

  return (
    <div className="job-detail">
      <div className="job-title">
        <h3>Job Details:</h3>
        <span className="job-name">{jobDescription}</span>
        <span className="jobtype-pill" style={{ backgroundColor: colourPicker(getJobTypeColour, jobTypes, jobType) }}>
          {enumChecker(jobType, jobTypes)}
        </span>
        <span className="jobtype-pill" style={{ backgroundColor: colourPicker(getJobPriorityColour, jobPriorities, jobPriority) }}>
          {`Priority: ${enumChecker(jobPriority, jobPriorities)}`}
        </span>
      </div>
      <div className="job-info">
        {/* <div className="job-field">
          <label>Date Created:</label>
          <span>{createdDate}</span>
        </div> */}
        <div className="job-field">
          <label>Due By:</label>
          <span>{DateTime.fromISO(dueByDate).toFormat('yyyy-MM-dd, hh:mm:ss')}</span>
        </div>
        <div className="job-field">
          <label>Notes:</label>
          <span>{notes}</span>
        </div>
      </div>
    </div>
  );
}

export default JobDetail;

import ErrorHandling from '../errors/ErrorHandling';

class FormValidator {
  constructor(form, validations) {
    this.form = form;
    this.validations = validations;
    this.errorHandling = ErrorHandling;
  }

  validate(errorHandler = this.errorHandling.toastifyError.bind(this.errorHandling)) {
    for (const validation of this.validations) {
      if (!this.form[validation.field]) {
        if (errorHandler) {
          errorHandler(validation.message);
        }
        return false;
      }
    }
    return true;
  }
}

export default FormValidator;

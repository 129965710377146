import React from "react";
import { colourPicker, getRouteStatusColour, getRoutePriorityColour } from "../../../../../utils/colour/Colours";
import { enumChecker } from "../../../../../utils/enums/Enums";
import { DateTime } from "luxon";
import "./route-detail.scss";

const RouteDetail = ({ route, routeStatuses, routePriorities }) => {
  const { routeName, dueByDate, createdDate, priority, routeStatus } = route;
  return (
    <div className="route-detail">
      <div className="route-title">
        <h3>Route Details:</h3>
        <span className="route-name">{routeName}</span>
        <span className="routestatus-pill" style={{ backgroundColor: colourPicker(getRouteStatusColour, routeStatuses, routeStatus) }}>
          {enumChecker(routeStatus, routeStatuses)}
        </span>
        <span className="routestatus-pill" style={{ backgroundColor: colourPicker(getRoutePriorityColour, routePriorities, priority) }}>
          {`Priority: ${enumChecker(priority, routePriorities)}`}
        </span>
      </div>
      <div className="route-info">
        <div className="route-field">
          <label>Date Created:</label>
          <span>{DateTime.fromISO(createdDate).toFormat('yyyy-MM-dd, hh:mm:ss')}</span>
        </div>
        <div className="route-field">
          <label>Due By:</label>
          <span>{DateTime.fromISO(dueByDate).toFormat('yyyy-MM-dd, hh:mm:ss')}</span>
        </div>
      </div>
    </div>
  );
}

export default RouteDetail;

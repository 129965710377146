import React, { useState, useEffect } from 'react';
import "./login.scss";
import "../../../scss/components/buttons.scss";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { useNavigation } from '../../../navigation/NavigationContext';
import { useAuth } from '../../../auth/AuthContext';
import { toast } from 'react-toastify';

const LoginSection = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);

  const { sternaAuth } = useAuth();
  const { sternaNavigator } = useNavigation();

  const navigate = useNavigate();

  useEffect(() => {
    if (loginSuccess) {
      if (!sternaNavigator.getSignInState()) {
        sternaNavigator.toggleSignIn();
      }
      navigate('/sterna/dashboard/jobs');
      toast.success("Login success!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        icon: "🎉"
      });
    }
  }, [loginSuccess, navigate, sternaNavigator]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Username: ", username, " | Password: ", password);
    const success = await sternaAuth.initiateLogin(username, password);
    setLoginSuccess(success);

    if (!success) {
      toast.error("Login failed!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        icon: "🤔"
      });
    }
    setUsername("");
    setPassword("");
  }

  const handleSignUp = (e) => {
    e.preventDefault();

    console.log("Navigate to Sign Up page...");
  }

  const handleUsernameUpdate = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordUpdate = (e) => {
    setPassword(e.target.value);
  }

  return (
    <div className='login-page'>
      <div className='login-page-left'>

      </div>
      <div className='login-page-right'>
        <div className="login-card">
          <p className="login-title">LOGIN</p>
          <form onSubmit={handleSubmit}>
            <input type="text" className="login-input" value={username} placeholder="username" onChange={handleUsernameUpdate} />
            <input type="password" className="login-input" value={password} placeholder="password" onChange={handlePasswordUpdate} />
            <p className="login-card-text adjust-left bottom-margin left-margin"><Link to={`/forgotpassword`} className="login-link">Forgot Password</Link></p>
            <button type="submit" className="sterna-button">Login</button>
            <button type="button" onClick={handleSignUp} className="sterna-button button-secondary">Sign Up</button>
          </form>
          <div className="login-card-footer">
            <p className="login-card-text">© 2023 Litheon (Pty) Ltd</p>
            <p className="login-card-text">
              <Link to={`/tnc`} className="login-link">Terms &amp; Conditions</Link> | <Link to={`/privacypolicy`} className="login-link">Privacy Policy</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSection;

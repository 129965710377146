import React from 'react';
import MainHeader from '../../components/sections/MainHeader';
import '../../scss/components/sections.scss';
import '../../scss/components/navbar.scss';
import LoginSection from '../../components/sections/LoginSection';

const Login = () => {
  return (
    <div>
      <MainHeader active="login">
        <LoginSection />
      </MainHeader>
    </div>
  );
};

export default Login;

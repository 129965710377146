import React from "react";
import '../../scss/components/formcomponents.scss';

const TextInput = ({ placeholder, value, onChange }) => (
  <input
    className="text-input"
    type="text"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

export default TextInput;

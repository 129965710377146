import React, { useState, useEffect } from "react";
import ApiService from "../../../../auth/ApiService";
import { faLifeRing } from "@fortawesome/free-solid-svg-icons";
import SideListContainer from "../../../../components/containers/SideListContainer";
import NoItem from "../../../../components/listitems/NoItem";
import TicketItem from "../../../../components/listitems/TicketItem";
import ItemDetailContainer from "../../../../components/containers/ItemDetailContainer";
import TitleSection from "../../../../components/sections/itemdetailsections/other/header/TitleSection";
import "./tickets-open.scss";

const TicketsOpen = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [ticketPriorities, setTicketPriorities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState();
  const [ticketDetail, setTicketDetail] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await ApiService.get('tickets/open');
        console.log("Open Tickets: ", response);
        if (response?.data?.tickets) {
          const openTickets = response.data.tickets.filter(ticket => ticket.status === 1 || ticket.status === "New");
          setTickets(openTickets);
          if (openTickets.length > 0) {
            setSelectedTicket(openTickets[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching open tickets: ', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchTicketStatuses = async () => {
      try {
        const response = await ApiService.get('enums/Ticket/TicketStatus');
        if (response?.data?.options) {
          setTicketStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching ticket statuses: ', error);
      }
    }

    const fetchTicketPriorities = async () => {
      try {
        const response = await ApiService.get('enums/Ticket/TicketPriority');
        if (response?.data?.options) {
          setTicketPriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching ticket priorities: ', error);
      }
    }

    fetchData();
    fetchTicketStatuses();
    fetchTicketPriorities();
  }, []);

  useEffect(() => {
    const fetchTicketDetail = async () => {
      if (selectedTicket) {
        setTicketDetail(null);
        try {
          const response = await ApiService.get(`tickets/${selectedTicket.id}`);
          if (response?.data?.ticket) {
            setTicketDetail(response.data.ticket);
          }
        } catch (error) {
          console.error(`Error fetching ticket detail for ${selectedTicket.id}: `, error);
        }
      }
    }

    fetchTicketDetail();
  }, [selectedTicket]);

  return (
    <div className="tickets-open-container">
      <SideListContainer loading={loading} semitransparentbg>
        {tickets.length > 0 ? tickets.map((ticket, index) => (
          <TicketItem key={index} ticket={ticket} ticketStatuses={ticketStatuses} selectTicket={setSelectedTicket} />
        )) : <NoItem title="📭 No Open Tickets" />}
      </SideListContainer>
      <ItemDetailContainer>
        {!ticketDetail ? <p>No Ticket Selected</p> : <>
          <TitleSection lighttitle title={`Ticket ${selectedTicket.id}`} icon={faLifeRing} deleteMethod={() => {}}/>
        </>}
      </ItemDetailContainer>
    </div>
  );
}

export default TicketsOpen;

import React, { useState, useEffect } from "react";
import Select from 'react-select';
import ApiService from "../../../../../auth/ApiService";
import ErrorHandling from '../../../../../utils/errors/ErrorHandling';
import SimpleButton from '../../../../buttons/SimpleButton';
import "./job-card-row-add.scss";

const JobCardRowAdd = () => {
  const [options, setOptions] = useState([{ value: "0", label: "No Job Items" }]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    const fetchJobItems = async () => {
      try {
        setLoadingOptions(true);
        const response = await ApiService.get("jobitems");
        console.log("Job Items: ", response);
        if (response?.data?.jobItems) {
          const items = response.data.jobItems;
          let itemOptions = items.length > 0 ? items.map(item => ({
            value: item.id,
            label: item.jobItemName
          })) : options;

          itemOptions.sort((a, b) => a.label.localeCompare(b.label));

          console.log("Item Options: ", itemOptions);
          setOptions(itemOptions);
        }
      } catch {
        ErrorHandling.toastifyError("Failed to fetch job items!");
      } finally {
        setLoadingOptions(false);
      }
    }

    fetchJobItems();
  }, []);

  const onSelectionChange = (selection) => {
    console.log("Selection: ", selection);
    setSelectedOption(selection);
  }

  return (
    <form className="new-job-card-item-form">
      <div className="label">
        <p>Add Job Item:</p>
      </div>
      <div className="selection-box">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={options[0]}
          isDisabled={false}
          isLoading={loadingOptions}
          isClearable
          isSearchable
          name="jobitemoptions"
          options={options}
          onChange={onSelectionChange}
          value={selectedOption}
        />
      </div>
      <div className="actions">
        <SimpleButton title="Add Item" />
      </div>
    </form>
  );
};

export default JobCardRowAdd;

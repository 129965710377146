import React from "react";
import { DateTime } from "luxon";
import { enumChecker } from "../../../utils/enums/Enums";
import { getIndexOfName } from "../../../utils/colour/Colours";
import RoutePriorityColours from "../../../utils/colour/RoutePriorityColours";
import "./route-item.scss";

const RouteItem = ({ route, routeStatuses, selectRoute = () => { } }) => {
  const isDueShortly = new Date(route.dueByDate).getTime() < (Date.now() + 24 * 60 * 60 * 1000);
  const isOverdue = new Date(route.dueByDate).getTime() < Date.now();

  let itemClass = "route-item";
  if (isOverdue) {
    itemClass = `${itemClass} overdue inside-overdue-shadow`;
  } else if (isDueShortly) {
    itemClass = `${itemClass} due-soon inside-due-shortly-shadow`;
  }

  const statusLabel = enumChecker(route.routeStatus, routeStatuses, routeStatuses.find(status => status.value === route.routeStatus));

  return (
    <div className={itemClass} onClick={() => selectRoute(route)}>
      <div className="priority-circle" data-priority={getIndexOfName(route.priority, RoutePriorityColours())}></div>
      <div className="route-info">
        <div className="route-header">
          <span className="route-name">{route.routeName}</span>
          <span className="status-pill" data-status={statusLabel}>{statusLabel}</span>
        </div>
        <div className="route-dates">
          <span className="create-date">{DateTime.fromISO(route.createdDate).toFormat('yyyy-MM-dd, hh:mm:ss')}</span>
          {(isDueShortly || isOverdue) && (
            <span className="due-pill" data-type={isOverdue ? 'overdue' : 'due-shortly'}>
              {isOverdue ? 'Overdue' : 'Due Shortly'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default RouteItem;

export default () => {
  return {
    "Delivery": "#1E90FF", // Dodger Blue (transportation)
    "Electrical": "#FFD700", // Gold (electricity)
    "Plumbing": "#4169E1", // Royal Blue (water systems)
    "Maintenance": "#DAA520", // Goldenrod (continuous care)
    "Installation": "#4B0082", // Indigo (setting up systems)
    "Inspection": "#32CD32", // Lime Green (reviewing/monitoring)
    "Audit": "#8B4513", // Saddle Brown (financial inspection)
    "Construction": "#A52A2A", // Brown (building)
    "Collect": "#FFA07A", // Light Salmon (gathering)
    "Transfer": "#7B68EE", // Medium Slate Blue (movement between points)
    "Pick Up": "#00FA9A", // Medium Spring Green (collection)
    "Drop Off": "#FF69B4", // Hot Pink (delivery endpoint)
  };
}
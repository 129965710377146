import axios from "axios";

export class BaseHelper {
  async makeGetRequest(url, config) {
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      console.error('Error making GET request: ', error);
      throw error;
    }
  }

  async makePostRequest(url, config) {
    try {
      const response = await axios.post(url, config);
      return response;
    } catch (error) {
      console.error('Error making POST request: ', error);
      throw error;
    }
  }

  async makePatchRequest(url, config) {
    try {
      const response = await axios.patch(url, config);
      return response;
    } catch (error) {
      console.error('Error making PATCH request: ', error);
      throw error;
    }
  }

  async makeDeleteRequest(url, config) {
    try {
      const response = await axios.delete(url, config);
      return response;
    } catch (error) {
      console.error('Error making DELETE request: ', error);
      throw error;
    }
  }
}
import React from "react";
import './skeleton-loader.scss';

const SkeletonLoaderList = ({ count }) => {
  return (
    <div className="skeleton-wrapper">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="skeleton-item">
          <div className="skeleton-avatar"></div>
          <div className="skeleton-content">
            <div className="skeleton-line skeleton-line-short"></div>
            <div className="skeleton-line"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoaderList;

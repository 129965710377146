import React, { useState, useEffect } from 'react';
import SideListContainer from '../../../../components/containers/SideListContainer';
import "./sites-all.scss";
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import ApiService from '../../../../auth/ApiService';
import SiteItem from '../../../../components/listitems/SiteItem';
import NoItem from '../../../../components/listitems/NoItem';
import TitleSection from '../../../../components/sections/itemdetailsections/other/header/TitleSection';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import DualColumn from '../../../../components/containers/DualColumn';
import SiteDetail from '../../../../components/sections/itemdetailsections/site/SiteDetail';
import ClientDetail from '../../../../components/sections/itemdetailsections/client/ClientDetail';

const SitesAll = () => {
  const [sites, setSites] = useState([]);
  const [siteStatuses, setSiteStatuses] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [siteDetail, setSiteDetail] = useState();
  const [siteLoading, setSiteLoading] = useState(false);
  const [sitesLoading, setSitesLoading] = useState(false);
  const [clientTypes, setClientTypes] = useState([]);

  useEffect(() => {
    const fetchAllSites = async () => {
      try {
        setSitesLoading(true);
        const sitesResponse = await ApiService.get('sites');
        if (sitesResponse?.data?.clientSites) {
          setSites(reformattedSites(sitesResponse?.data?.clientSites));
        }
      } catch (error) {
        console.error('Error fetching all sites: ', error);
      } finally {
        setSitesLoading(false);
      }
    }

    const fetchSiteStatuses = async () => {
      try {
        const response = await ApiService.get('enums/Site/SiteStatus');
        if (response?.data?.options) {
          setSiteStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching site statuses: ', error);
      }
    }

    const fetchSiteTypes = async () => {
      try {
        const response = await ApiService.get('enums/Site/SiteType');
        if (response?.data?.options) {
          setSiteTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching site types: ', error);
      }
    }

    const fetchClientTypes = async () => {
      try {
        const response = await ApiService.get('enums/Client/ClientType');
        if (response?.data?.options) {
          setClientTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching client types: ', error);
      }
    }

    fetchAllSites();
    fetchSiteStatuses();
    fetchSiteTypes();
    fetchClientTypes();
  }, []);

  useEffect(() => {
    const fetchSiteById = async (site) => {
      try {
        setSiteLoading(true);
        const siteResponse = await ApiService.get(`sites/${site.id}`);
        setSiteDetail(siteResponse?.data?.site);
        console.log("Selected Site: ", siteResponse?.data);
        // Fetch client detail:
        //const clientsResponse = await ApiService.get("client")
      } catch (error) {
        console.error(`Error fetching site with id ${site.id}: `, error);
      } finally {
        setSiteLoading(false);
      }
    }

    if (selectedSite) {
      fetchSiteById(selectedSite);
    } else {
      setSiteDetail(undefined);
    }
  }, [selectedSite]);

  const reformattedSites = (clientSites) => clientSites.flatMap(client => {
    const newSitesArray = client.sites.map(site => ({
      ...site,
      clientId: client.clientId,
      clientName: client.clientName,
    }));

    return [...newSitesArray].sort((a, b) => {
      if (a.siteName < b.siteName) return -1;
      if (a.siteName > b.siteName) return 1;
      return 0;
    });
  });

  const deleteSite = () => {
    console.log("Delete this site");
  }

  const LeftComponent = ({ detail, types }) => (
    <>
      <SiteDetail site={detail} siteTypes={types} extendedDetail />
    </>
  );

  const RightComponent = ({ types }) => (
    <DualColumn
      leftChildren={<ClientDetail clientName="Bright Light Solar VCC Ltd" clientTypes={types} clientType="Corporate" />} // TODO: Change this!!
      rightChildren={<></>}
    />
  );

  return (
    <div className='sites-all-container'>
      <SideListContainer loading={sitesLoading} semitransparentbg>
        {sites.length > 0 ? sites.map((site, index) => (
          <SiteItem key={index} site={site} siteStatuses={siteStatuses} selectSite={setSelectedSite} />
        )) : <NoItem title="📭 No Sites" />}
      </SideListContainer>
      <ItemDetailContainer>
          {!siteDetail ? <p>No Site Selected!</p> : <>
            <TitleSection lighttitle title={`${siteDetail.siteCode} | ${siteDetail.siteAlternateId && `${siteDetail.siteAlternateId} | `}${siteDetail.siteName}`} icon={faMapMarkerAlt} deleteMethod={deleteSite} />
            <DualColumn
              leftChildren={<LeftComponent detail={siteDetail} types={siteTypes} />}
              rightChildren={<RightComponent types={clientTypes} />}
            />
          </>}
      </ItemDetailContainer>
    </div>
  );
}

export default SitesAll;
import React from "react";
import { DateTime } from "luxon";
import { enumChecker } from "../../../utils/enums/Enums";
import { getIndexOfName } from "../../../utils/colour/Colours";
import TicketPriorityColours from "../../../utils/colour/TicketPriorityColours";
import "./ticket-item.scss";

const TicketItem = ({ ticket, ticketStatuses, selectTicket = () => { } }) => {
  const isDueShortly = new Date(ticket.dueDate).getTime() < (Date.now() + 24 * 60 * 60 * 1000);
  const isOverdue = new Date(ticket.dueDate).getTime() < Date.now();

  let itemClass = "ticket-item";
  if (isOverdue) {
    itemClass = `${itemClass} overdue inside-overdue-shadow`;
  } else if (isDueShortly) {
    itemClass = `${itemClass} due-soon inside-due-shortly-shadow`;
  }

  const statusLabel = enumChecker(ticket.status, ticketStatuses, ticketStatuses.find(status => status.value === ticket.status));

  return (
    <div className={itemClass} onClick={() => selectTicket(ticket)}>
      <div className="priority-circle" data-priority={getIndexOfName(ticket.priority, TicketPriorityColours())}></div>
      <div className="ticket-info">
        <div className="ticket-header">
          <span className="ticket-name">{ticket.id}</span>
          <span className="status-pill" data-status={statusLabel}>{statusLabel}</span>
        </div>
        <div className="ticket-dates">
          <span className="create-date">{DateTime.fromISO(ticket.createdDate).toFormat('yyyy-MM-dd, hh:mm:ss')}</span>
          {(isDueShortly || isOverdue) && (
            <span className="due-pill" data-type={isOverdue ? 'overdue' : 'due-shortly'}>
              {isOverdue ? 'Overdue' : 'Due Shortly'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketItem;

import React, { useState } from "react";
import { faPencilAlt, faChainBroken } from "@fortawesome/free-solid-svg-icons";
import SimpleButton from "../../buttons/SimpleButton";
import "./job-row.scss";

const JobRow = ({
  sitename, 
  jobdescription,
  jobtype,
  priority,
  noEdit = false,
  entityToEdit = "",
  noDelink = false,
  onDelink = () => {},
  editComponent
}) => {
  const [showDetail, setShowDetail] = useState(false);

  const onEdit = () => {
    setShowDetail(!showDetail);
  }

  return (
    <div className="job-row-wrapper">
      <div className="job-row">
        <span>{sitename}</span>
        <span>{jobdescription}</span>
        <span>{jobtype}</span>
        <span>{priority}</span>
        <div className="button-area">
          {!noEdit ? <SimpleButton title={`Edit ${entityToEdit}`} icon={faPencilAlt} onClick={onEdit} /> : null}
          {!noDelink ? <SimpleButton title="Delink" icon={faChainBroken} onClick={onDelink} /> : null}
        </div>
      </div>
      {
        !noEdit ?
          <div className={showDetail ? "jobs-edit expanded" : "jobs-edit collapsed"}>
            {editComponent}
          </div> :
          null
      }
    </div>
  );
};

export default JobRow;

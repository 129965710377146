import React from 'react';
import SkeletonLoaderList from '../widgets/SkeletonLoaderList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const SideListContainer = ({
  loading,
  nobg = false,
  topborder = false,
  semitransparentbg = false,
  canminimize = false,
  isVisible = true,
  toggleSidebar = () => { },
  topComponent = <div></div>,
  children
}) => {
  let containerClass = nobg ? "sidelistcontainer no-bg" : "sidelistcontainer";
  containerClass = topborder ? `${containerClass} top-border` : containerClass;
  containerClass = semitransparentbg ? `${containerClass} semi-transparent-bg` : containerClass;
  containerClass = isVisible ? `${containerClass} visible` : `${containerClass} hidden`;

  return (
    <div className={isVisible ? "sidelist-wrapper visible" : "sidelist-wrapper hidden"}>
      {canminimize ?
      <button className="toggle-tab" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isVisible ? faAngleDoubleLeft : faAngleDoubleRight} />
      </button> : null}
      <div className={containerClass}>
        {loading ? <SkeletonLoaderList count={3} /> : <>{topComponent}<ul className={isVisible ? "fade-in" : "fade-out"}>{children}</ul></>}
      </div>
    </div>
  );
}

export default SideListContainer;

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import './simple-button.scss';

const SimpleButton = ({ disabled, baseClass = "simple-button-base", title = "Button", icon = faPlusSquare, onClick = () => { } }) => {
  const buttonClass = !disabled ? baseClass : `${baseClass} disabled`.trim();
  const iconClass = !disabled ? "simple-button-icon" : "simple-button-icon disabled";

  return (
    <button
      type="button"
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} className={iconClass} /> {title}
    </button>
  );
};

export default SimpleButton;

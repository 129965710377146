// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(114, 178, 197, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  transform: translateY(100%);
  z-index: 125; }
  .loading-overlay.visible {
    transform: translateY(0%); }
  .loading-overlay .loader {
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 5px solid #72B2C5;
    border-bottom: 5px solid #4490a6;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    z-index: 126; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }
`, "",{"version":3,"sources":["webpack://./src/components/widgets/FullPageLoader/loading-overlay.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,0CAA0C;EAC1C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;EAChC,2BAA2B;EAC3B,YAAY,EAAA;EAZhB;IAeQ,yBAAyB,EAAA;EAfjC;IAmBQ,0CAA0C;IAC1C,kBAAkB;IAClB,6BAA6B;IAC7B,gCAA6C;IAC7C,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,YAAY,EAAA;;AAEZ;EACI;IACI,yBAAyB,EAAA,EAAA","sourcesContent":[".loading-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(114, 178, 197, 0.8); // Adding alpha channel for transparency\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: all 0.5s ease-in-out;\n    transform: translateY(100%);\n    z-index: 125;\n\n    &.visible {\n        transform: translateY(0%);\n    }\n\n    .loader {\n        border: 5px solid rgba(255, 255, 255, 0.3);\n        border-radius: 50%;\n        border-top: 5px solid #72B2C5;\n        border-bottom: 5px solid darken(#72B2C5, 15%);\n        width: 50px;\n        height: 50px;\n        animation: spin 1s linear infinite;\n        z-index: 126;\n\n        @keyframes spin {\n            100% {\n                transform: rotate(360deg);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import './no-item-selected.scss';

const NoItemSelected = ({ item }) => (
  <div className="no-item-selected-section">
    <h2>{`No ${item} Selected`}</h2>
    <p>{`Select ${item.toLowerCase()} from list`}</p>
  </div>
);

export default NoItemSelected;

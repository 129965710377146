import React from 'react';
import TopContainer from '../../../../components/containers/TopContainer';
import SubNavContainer from '../../../../components/containers/SubNavContainer';
import './fleetreports.scss';

const FleetReports = () => {
  return (
    <>
      <TopContainer />
      <SubNavContainer>
        {/* <ul>
          <li><Link to={`/sterna/dashboard/clients/new`}><FontAwesomeIcon icon={faPlusSquare} className='awesome-icon' /> New Client</Link></li>
          <li><Link to={`/sterna/dashboard/clients/all`}><FontAwesomeIcon icon={faUsers} className='awesome-icon' /> Clients</Link></li>
        </ul> */}
      </SubNavContainer>
      <div className='operations-section'>
        <div className='fleetreports-container'>
          <h1>Fleet Reports</h1>
        </div>
      </div>
    </>
  );
}

export default FleetReports;

import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import TopContainer from '../../../../components/containers/TopContainer';
import SubNavContainer from '../../../../components/containers/SubNavContainer';

const Sites = () => {
  return (
    <>
      <TopContainer />
      <SubNavContainer>
        <ul>
          <li><Link to={`/sterna/dashboard/sites/new`}><FontAwesomeIcon icon={faPlusSquare} className='awesome-icon'/> New Site</Link></li>
          <li><Link to={`/sterna/dashboard/sites/all`}><FontAwesomeIcon icon={faMapMarkerAlt} className='awesome-icon'/> Sites</Link></li>
        </ul>
      </SubNavContainer>
      <div className='operations-section'>
        <Outlet />
      </div>
    </>
  );
};

export default Sites;
import React, { createContext, useContext, useState, useEffect } from 'react';
import { SternaAuth } from './SternaAuth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [sternaAuth, setSternaAuth] = useState(new SternaAuth());

  useEffect(() => {
    const unsubscribe = sternaAuth.subscribeToChanges((isAuthenticated) => {
      setSternaAuth(prev => ({...prev, isAuthenticated}));
    });
    return unsubscribe;
  }, [sternaAuth]);

  return (
    <AuthContext.Provider value={{ sternaAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
import React from "react";
import { useNavigation } from "../../navigation/NavigationContext";
import { useAuth } from "../../auth/AuthContext";
import { useNavigate } from "react-router-dom";

const TopContainer = () => {
  const { sternaNavigator } = useNavigation();
  const { sternaAuth } = useAuth();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();

    if (sternaNavigator.getSignInState()) {
      localStorage.removeItem('sternatoken');
      sternaNavigator.toggleSignIn();
      sternaAuth.setToken('nothing');
      navigate("/");
    }
  }

  return (
    <div className="topcontainer">
      <div className="topcontainer-left">

      </div>
      <div className="topcontainer-right">
        <button type="button" className="logoutbutton" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default TopContainer;
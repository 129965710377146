import React, { useState, useEffect } from 'react';
import ApiService from '../../../../auth/ApiService';
import { useAuth } from '../../../../auth/AuthContext';
import { faTruck, faStamp, faRocket } from '@fortawesome/free-solid-svg-icons';
import SideListContainer from '../../../../components/containers/SideListContainer';
import RouteItem from '../../../../components/listitems/RouteItem';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import TitleSection from '../../../../components/sections/itemdetailsections/other/header/TitleSection';
import RouteDetail from '../../../../components/sections/itemdetailsections/route/RouteDetail';
import JobsDetail from '../../../../components/sections/itemdetailsections/job/JobsDetail';
import NoItem from '../../../../components/listitems/NoItem';
import "../../../../scss/components/buttons.scss";
import "./routes-all.scss";
import ErrorHandling from '../../../../utils/errors/ErrorHandling';
import SimpleButton from '../../../../components/buttons/SimpleButton';

const RoutesAll = () => {
  const { sternaAuth } = useAuth();

  const [companyId, setCompanyId] = useState();
  const [routes, setRoutes] = useState([]);
  const [routeStatuses, setRouteStatuses] = useState([]);
  const [routePriorities, setRoutePriorities] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRoute, setSelectedRoute] = useState();
  const [routeDetail, setRouteDetail] = useState();

  const [jobTypes, setJobTypes] = useState();
  const [jobPriorities, setJobPriorities] = useState();

  const [routeUpdateTrigger, setRouteUpdateTrigger] = useState(0);
  const [canApprove, setCanApprove] = useState(false);
  const [canRelease, setCanRelease] = useState(false);

  useEffect(() => {
    const token = sternaAuth.getToken();
    const claims = sternaAuth.parseJwt(token);
    console.log('Claims: ', claims);
    
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await ApiService.get('routes');
        if (response?.data?.routes) {
          console.log("Routes: ", response.data.routes);
          const openRoutes = response.data.routes.filter(route => route.routeStatus === "New");
          console.log("Open Routes: ", openRoutes);
          if (openRoutes.length > 0) {
            setRoutes(openRoutes);
            setSelectedRoute(openRoutes[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching routes: ', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRouteStatuses = async () => {
      try {
        const response = await ApiService.get('enums/Route/RouteStatus');
        if (response?.data?.options) {
          setRouteStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching route statuses: ', error);
      }
    }

    const fetchRoutePriorities = async () => {
      try {
        const response = await ApiService.get('enums/Route/RoutePriority');
        if (response?.data?.options) {
          setRoutePriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching route priorities: ', error);
      }
    }

    const fetchJobTypes = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobType');
        if (response?.data?.options) {
          setJobTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job types: ', error);
      }
    }

    const fetchJobPriorities = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobPriority');
        if (response?.data?.options) {
          setJobPriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job priorities: ', error);
      }
    }

    const fetchCompany = async () => {
      try {
        const response = await ApiService.get(`companies/bu-user/${claims.UserId ?? '0'}`);
        console.log('Company fetch response: ', response);
        if (response?.data?.message === 'Success') {
          console.log('Company: ', response?.data);
          setCompanyId(response.data.company?.id);
        }
      } catch (error) {
        console.error('Failed to fetch company for user with id: ', claims.UserId, ' | reason: ', error);
      }
    }

    fetchData();
    fetchRouteStatuses();
    fetchRoutePriorities();
    fetchJobTypes();
    fetchJobPriorities();
    fetchCompany();
  }, []);

  useEffect(() => {
    const fetchSelectedRoute = async () => {
      if (selectedRoute) {
        try {
          console.log('Selected Route Id: ', selectedRoute.id);
          const response = await ApiService.get(`routes/${selectedRoute.id}`);
          console.log(response);
          if (response?.data?.route) {
            setRouteDetail(response.data.route);
            var jobs = response.data.route.jobs;
            setCanApprove(jobs.length > 0);
            setCanRelease(jobs.length > 0);
          }
          else {
            setCanApprove(false);
            setCanRelease(false);
          }
        } catch (error) {
          console.error('Error fetching route detail: ', error);
          setCanApprove(false);
          setCanRelease(false);
        }
      }
    }

    fetchSelectedRoute();
  }, [selectedRoute, routeUpdateTrigger]);

  const selectRoute = (routeSelected) => {
    console.log(routeSelected);
    setSelectedRoute(routeSelected);
    setRouteUpdateTrigger(prev => prev + 1);
  }

  const deleteRoute = async () => {
    console.log(`Deleting route ${selectedRoute.id}`);
    try {
      const cancelRouteResponse = await ApiService.patch("Routes/status/cancelled", {
        routeId: selectedRoute.id,
        routeStatus: "Cancelled",
        apiKey: process.env.REACT_APP_MASTER_API_KEY
      });
  
      if (!cancelRouteResponse?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }
  
      if (cancelRouteResponse.data.message !== "Update Success") {
        return ErrorHandling.toastifyError(cancelRouteResponse.data.message);
      }

      setRoutes(routes.filter(rt => rt.id !== routeDetail.id));
      
      if (selectedRoute && selectedRoute?.id === routeDetail.id) {
        setSelectedRoute(null);
        setRouteDetail(null);
      }
  
      ErrorHandling.toastifySuccess("Route Deleted!");
    } catch {
      return ErrorHandling.toastifyError("Unknown Error!");
    }
  }

  const delinkJobFromRoute = async (jobId) => {
    try {
      const response = await ApiService.delete(`Jobs/route`, {
        apiKey: process.env.REACT_APP_MASTER_API_KEY,
        jobId,
        routeId: selectedRoute.id
      });

      if (!response?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }

      if (response.data.message !== "Unlink Success") {
        return ErrorHandling.toastifyError(response.data.message);
      }

      setRouteUpdateTrigger(prev => prev + 1);

      ErrorHandling.toastifySuccess("Job Unlinked!");
    } catch {
      return ErrorHandling.toastifyError("Unknown Error!");
    }
  }

  const defaultRelease = async () => {
    console.log("Process begins...");
    // This will be hardcoded for Mkateko / SPC use for now...
    try {
      // If the company id is not set we do not continue:
      if (!companyId) {
        return ErrorHandling.toastifyError("Company Id is undefined!");
      }

      // Get the related template to use:
      let templateId;
      if (companyId === '78d352e5-def2-4020-9ce6-4c0cc2d664bb') {
        templateId = "40990662-a384-4c9f-983e-bf67d0e360b4";
      }
      if (companyId === 'f9864b94-bb29-40aa-a954-924660bb55d1') {
        templateId = "35523292-1b8e-48b3-994b-7cf727f97750";
      }

      // If the template id was not set we also do not continue:
      if (!templateId) {
        return ErrorHandling.toastifyError("Template was not found!");
      }

      // First we mark the route as approved:
      console.log("Mark route as approved...");
      const approveRouteResponse = await ApiService.patch("Routes/status/approved", {
        routeId: selectedRoute.id,
        routeStatus: "Approved",
        apiKey: process.env.REACT_APP_MASTER_API_KEY
      });

      console.log("Approval message: ", approveRouteResponse);
      if (!approveRouteResponse?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }

      if (approveRouteResponse.data.message !== "Update Success") {
        return ErrorHandling.toastifyError(approveRouteResponse.data.message);
      }

      console.log("Route marked as Approved!");

      // We then run the scaffold for each of the jobs:
      if (routeDetail.jobs) {
        routeDetail.jobs.forEach(async job => {
          const scaffoldResponse = await ApiService.post("JobCards/scaffold", {
            apiKey: process.env.REACT_APP_MASTER_API_KEY,
            jobId: job.jobDetail.id,
            templateId
          });

          if (!scaffoldResponse?.data?.message) {
            console.error(`Scaffold failed for job ${job.id}`);
          }
        });
      }

      console.log("Scaffolding run!");

      // Mark the route as planned:
      const plannedRouteResponse = await ApiService.patch("Routes/status/planned", {
        routeId: selectedRoute.id,
        routeStatus: "Planned",
        apiKey: process.env.REACT_APP_MASTER_API_KEY
      });

      if (!plannedRouteResponse?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }

      if (plannedRouteResponse.data.message !== "Update Success") {
        return ErrorHandling.toastifyError(plannedRouteResponse.data.message);
      }

      console.log("Route marked as Planned!");

      // Add the start and end locations:
      const locationsAddResponse = await ApiService.patch("Routes/planning/startend", {
        apiKey: process.env.REACT_APP_MASTER_API_KEY,
        routeId: selectedRoute.id,
        startLocationId: "cb038ef2-b330-43cc-acf5-698e3db7a809",  // This is set to SPC's office
        endLocationId: "cb038ef2-b330-43cc-acf5-698e3db7a809"     // This is set to SPC's office
      });

      if (!locationsAddResponse?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }

      if (locationsAddResponse.data.message !== "Update Success") {
        return ErrorHandling.toastifyError(locationsAddResponse.data.message);
      }

      console.log("Locations Added!");

      console.log(`RouteId to optimize: ${selectedRoute.id}`);
      
      // Run route optimization:
      const optimizedRouteResponse = await ApiService.post("Routes/optimize", {
        apiKey: process.env.REACT_APP_MASTER_API_KEY,
        routeId: selectedRoute.id
      });

      if (!optimizedRouteResponse?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }

      if (optimizedRouteResponse.data.message !== "Update Success") {
        return ErrorHandling.toastifyError(optimizedRouteResponse.data.message);
      }

      console.log("Route optimized!");

      // Mark the route as released:
      const releaseRouteResponse = await ApiService.patch("Routes/status/released", {
        routeId: selectedRoute.id,
        routeStatus: "Released",
        apiKey: process.env.REACT_APP_MASTER_API_KEY
      });

      if (!releaseRouteResponse?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }

      if (releaseRouteResponse.data.message !== "Update Success") {
        return ErrorHandling.toastifyError(releaseRouteResponse.data.message);
      }

      console.log("Route Released!");

      setRoutes(routes.filter(rt => rt.id !== routeDetail.id));
      
      if (selectedRoute && selectedRoute?.id === routeDetail.id) {
        setSelectedRoute(null);
        setRouteDetail(null);
      }

      ErrorHandling.toastifySuccess("Route Released!");
    } catch {
      return ErrorHandling.toastifyError("Unknown Error!");
    }
  }

  const approveRoute = async () => {
    try {
      console.log("Mark route as approved...");
      const approveRouteResponse = await ApiService.patch("Routes/status/approved", {
        routeId: selectedRoute.id,
        routeStatus: "Approved",
        apiKey: process.env.REACT_APP_MASTER_API_KEY
      });
      
      console.log("Approval message: ", approveRouteResponse);
      if (!approveRouteResponse?.data?.message) {
        return ErrorHandling.toastifyError("Unknown Server Error!");
      }

      if (approveRouteResponse.data.message !== "Update Success") {
        return ErrorHandling.toastifyError(approveRouteResponse.data.message);
      }

      setRoutes(routes.filter(rt => rt.id !== routeDetail.id));
      
      if (selectedRoute && selectedRoute?.id === routeDetail.id) {
        setSelectedRoute(null);
        setRouteDetail(null);
      }

      ErrorHandling.toastifySuccess("Route Approved!");
    } catch {
      return ErrorHandling.toastifyError("Unknown Error!");
    }
  }

  return (
    <div className='routes-all-container'>
      <SideListContainer loading={loading} semitransparentbg>
        {routes.length > 0 ? routes.map((route, index) => (
          <RouteItem key={index} route={route} routeStatuses={routeStatuses} selectRoute={selectRoute} />
        )) : <NoItem title="📭 No Open Routes" />}
      </SideListContainer>
      <ItemDetailContainer>
        {!routeDetail ? <p>No Route Selected</p> : <>
          <TitleSection lighttitle title={routeDetail.routeName} icon={faTruck} deleteMethod={deleteRoute} canDelete={routeDetail.jobs.length === 0}>
            <SimpleButton title="Approve Route" icon={faStamp} disabled={!canApprove} onClick={approveRoute} />
            <SimpleButton title="Default Release" icon={faRocket} disabled={!canRelease} onClick={defaultRelease} />
          </TitleSection>
          {!routeDetail ? null : <RouteDetail route={routeDetail} routeStatuses={routeStatuses} routePriorities={routePriorities} />}
          {!routeDetail.jobs ? null : <JobsDetail jobs={routeDetail.jobs} jobTypes={jobTypes} jobPriorities={jobPriorities} onDelink={delinkJobFromRoute} noEdit />}
          {/* <DefaultRelease canRelease={routeDetail.jobs.length > 0} onSubmit={defaultRelease} /> */}
        </>}
      </ItemDetailContainer>
    </div>
  );
}

export default RoutesAll;
import React, { useState, useEffect } from "react";
import InputTypeHeader from "./InputTypeHeader";
import "../../scss/components/sections.scss";
import "../../scss/components/buttons.scss";

const InputContainerForm = ({ onSubmit = () => { }, expanded, loading = false, hasDifferences = false, onUndo = () => { }, children }) => {
  const buttonUndoClass = !loading && hasDifferences ? "undo-button" : "undo-button disabled";
  const buttonSaveClass = !loading && hasDifferences ? "item-complete" : "item-complete disabled";

  return (
    <form className={expanded ? "input-info expanded" : "input-info collapsed"} style={{ marginTop: "15px", paddingTop: "15px", borderTop: "1px solid #ccc" }} onSubmit={onSubmit}>
      {children}
      <div className="input-save">
        <button type="button" disabled={loading || !hasDifferences} onClick={onUndo} className={buttonUndoClass} style={{ marginBottom: "0px", marginRight: "10px" }}>↩️ Discard Changes</button>
        <button type="submit" disabled={loading || !hasDifferences} className={buttonSaveClass}>{loading ? "Saving..." : "💾 Save"}</button>
      </div>
    </form>
  );
}

const InputContainer = ({ onSubmit = () => { }, jobcard, jobItemStatuses, loading = false, hasDifferences = false, onUndo = () => { }, children }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  }

  return (
    <div className="input-detail">
      <InputTypeHeader jobcard={jobcard} jobItemStatuses={jobItemStatuses} expanded={expanded} setExpanded={toggleExpansion} hasDifferences={hasDifferences} />
      <InputContainerForm onSubmit={onSubmit} expanded={expanded} loading={loading} hasDifferences={hasDifferences} onUndo={onUndo}>{children}</InputContainerForm>
    </div>
  );
}

export default InputContainer;

import React, { useState, useEffect } from 'react';
import ApiService from '../../../../auth/ApiService';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import SideFormContainer from '../../../../components/containers/SideFormContainer';
import './sites-new.scss';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import LabeledInput from '../../../../components/formcomponents/LabeledInput';
import LabeledMultilineInput from '../../../../components/formcomponents/LabeledMultilineInput';
import LabeledSelect from '../../../../components/formcomponents/LabeledSelect';
import FullPageLoader from '../../../../components/widgets/FullPageLoader';
import { tryParseFloat } from '../../../../utils/parsing/tryparsers';
import ErrorHandling from '../../../../utils/errors/ErrorHandling';

const SitesNew = () => {
  const [creating, setCreating] = useState(false);
  
  const [sitename, setSitename] = useState('');
  const [siteid, setSiteid] = useState('');
  const [sitealtid, setSitealtid] = useState('');
  const [sitedescription, setSitedescription] = useState('');
  const [sitetype, setSitetype] = useState();
  const [sitetypes, setSitetypes] = useState([]);
  const [loadingSiteTypes, setLoadingSiteTypes] = useState(false);
  const [client, setClient] = useState();
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  const [clientregion, setClientregion] = useState('');
  const [area, setArea] = useState('');
  const [hasl, setHasl] = useState('');
  const [addressname, setAddressname] = useState('');
  const [addressdescription, setAddressdescription] = useState('');
  const [unitnumber, setUnitnumber] = useState('');
  const [building, setBuilding] = useState('');
  const [complex, setComplex] = useState('');
  const [street, setStreet] = useState('');
  const [intersection, setIntersection] = useState('');
  const [addresslinetwo, setAddresslinetwo] = useState('');
  const [suburb, setSuburb] = useState('');
  const [extension, setExtension] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [postalcode, setPostalcode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [whatthreewords, setWhatthreewords] = useState('');
  const [accessinstructions, setAccessinstructions] = useState('');

  useEffect(() => {
    const fetchSiteTypesData = async () => {
      try {
        setLoadingSiteTypes(true);
        const response = await ApiService.get('enums/Site/SiteType');
        if (response?.data?.options) {
          setSitetypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching site types: ', error);
      } finally {
        setLoadingSiteTypes(false);
      }
    }

    const fetchCompanyClients = async () => {
      try {
        setLoadingClients(true);
        const response = await ApiService.get('clients');
        if (response?.data?.clients) {
          setClients(response.data.clients.map((client) => {
            return {
              value: client.id,
              label: (client.clientType === 'Individual' || client.clientType === 0) ? `${client.firstName} ${client.lastName}`.trimEnd() : client.companyName
            };
          }));
        }
      } catch (error) {
        console.error('Error fetching clients: ', error);
      } finally {
        setLoadingClients(false);
      }
    }

    const fetchCountries = async () => {
      try {
        setLoadingCountries(true);
        const response = await ApiService.get('countries');
        if (response?.data?.countries) {
          setCountries(response.data.countries.map((country) => {
            return {
              value: country.id,
              label: country.countryName
            };
          }));
        }
      } catch (error) {
        console.error('Error fetching countries: ', error);
      } finally {
        setLoadingCountries(false);
      }
    }

    fetchSiteTypesData();
    fetchCompanyClients();
    fetchCountries();
  }, []);

  const handleSiteNameChange = (event) => {
    setSitename(event.target.value);
  }

  const handleSiteIdChange = (event) => {
    setSiteid(event.target.value);
  }

  const handleSiteAltIdChange = (event) => {
    setSitealtid(event.target.value);
  }

  const handleSiteDescriptionChange = (event) => {
    setSitedescription(event.target.value);
  }

  const handleSiteTypeChange = (selectedOption) => {
    setSitetype(selectedOption);
  }

  const handleClientChange = (selectedOption) => {
    setClient(selectedOption);
  }

  const handleClientRegionChange = (event) => {
    setClientregion(event.target.value);
  }

  const handleAreaChange = (event) => {
    setArea(event.target.value);
  }

  const handleHaslChange = (event) => {
    setHasl(event.target.value);
  }

  const handleAddressNameChange = (event) => {
    setAddressname(event.target.value);
  }

  const handleAddressDescriptionChange = (event) => {
    setAddressdescription(event.target.value);
  }

  const handleUnitNumberChange = (event) => {
    setUnitnumber(event.target.value);
  }

  const handleBuildingChange = (event) => {
    setBuilding(event.target.value);
  }

  const handleComplexChange = (event) => {
    setComplex(event.target.value);
  }

  const handleStreetChange = (event) => {
    setStreet(event.target.value);
  }

  const handleIntersectionChange = (event) => {
    setIntersection(event.target.value);
  }

  const handleAddressLineTwoChange = (event) => {
    setAddresslinetwo(event.target.value);
  }

  const handleSuburbChange = (event) => {
    setSuburb(event.target.value);
  }

  const handleExtensionChange = (event) => {
    setExtension(event.target.value);
  }

  const handleCityChange = (event) => {
    setCity(event.target.value);
  }

  const handleProvinceChange = (event) => {
    setProvince(event.target.value);
  }

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
  }

  const handlePostalCodeChange = (event) => {
    setPostalcode(event.target.value);
  }

  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
  }

  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);
  }

  const handleWhatThreeWordsChange = (event) => {
    setWhatthreewords(event.target.value);
  }

  const handleAccessInstructionsChange = (event) => {
    setAccessinstructions(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!sitename || sitename.length < 1) {
      return ErrorHandling.toastifyError("Please provide a valid site name!");
    }

    if (!siteid || siteid.length < 1) {
      return ErrorHandling.toastifyError("Please provide a valid site code!");
    }

    if (!sitetype) {
      return ErrorHandling.toastifyError("Please select a site type!");
    }

    if (!client) {
      return ErrorHandling.toastifyError("Please select a client!");
    }

    if (!addressname || addressname?.length < 1) {
      return ErrorHandling.toastifyError("Please provide a valid address name!");
    }

    if (!addressdescription || addressdescription?.length < 1) {
      return ErrorHandling.toastifyError("Please provide a valid description for the address!");
    }

    if (!street || street?.length < 1) {
      return ErrorHandling.toastifyError("Please provide a valid street number and name!");
    }

    if (!city || city?.length < 1) {
      return ErrorHandling.toastifyError("A city name is required!");
    }

    if (!country) {
      return ErrorHandling.toastifyError("Please select a valid country!");
    }

    if (!postalcode || postalcode?.length < 1) {
      return ErrorHandling.toastifyError("Provide a Postal Code!");
    }

    const haslParseResult = tryParseFloat(hasl ?? '0.0');
    if (!haslParseResult?.success) {
      return ErrorHandling.toastifyError("Height Above Sea Level value is not a valid decimal value!");
    }

    const latsParseResult = tryParseFloat(latitude ?? '0.0');
    if (!latsParseResult?.success) {
      return ErrorHandling.toastifyError("Latitude value is not a valid decimal value!");
    }

    const longsParseResult = tryParseFloat(longitude ?? '0.0');
    if (!longsParseResult?.success) {
      return ErrorHandling.toastifyError("Longitude value is not a valid decimal value!");
    }

    const requestBody = {
      apiKey: process.env.REACT_APP_MASTER_API_KEY,
      site: {
        clientId: client?.value,
        siteName: sitename,
        siteCode: siteid,
        siteAlternateId: sitealtid,
        siteType: sitetype?.value,
        siteDescription: sitedescription,
        clientRegion: clientregion,
        area,
        heightAboveSeaLevel: haslParseResult.value,
        physicalAddress: {
          addressName: addressname,
          addressDescription: addressdescription,
          unitNumber: unitnumber,
          building,
          complex,
          street,
          intersection,
          addressLineTwo: addresslinetwo,
          suburb,
          extension,
          city,
          state: province,
          countryId: country?.value,
          zip: postalcode,
          latitude: latsParseResult.value,
          longitude: longsParseResult.value,
          whatThreeWords: whatthreewords,
          accessInstructions: accessinstructions
        }
      }
    };

    try {
      setCreating(true);
      var response = await ApiService.post('sites', requestBody);
      if (response?.data?.message !== 'Create Success') {
        console.error("Request failed: ", response);
        return ErrorHandling.toastifyError("Failed to create new Site!");
      }
      ErrorHandling.toastifySuccess("New Site was created!");
    } catch (error) {
      console.error("Failed to create new Site!");
      ErrorHandling.toastifyError("Failed to create new Site!");
    } finally {
      setCreating(false);
    }
  }

  return (
    <div className='sites-new-container'>
      <SideFormContainer
        formtitle="Configure New Work Location"
        icon={faMapMarkerAlt}
        buttonTitle="Create Site"
        loading={creating}
        onSubmit={handleSubmit}
        fancy
      >
        <p>Site Information:</p>
        <LabeledInput
          label="Site Name:"
          placeholder="ABC Site Alpha"
          value={sitename}
          onChange={handleSiteNameChange}
        />
        <LabeledInput
          label="Site Code:"
          placeholder="Site ID"
          value={siteid}
          onChange={handleSiteIdChange}
        />
        <LabeledInput
          label="Site Alternate ID:"
          placeholder="Site Alternate ID"
          value={sitealtid}
          onChange={handleSiteAltIdChange}
        />
        <LabeledMultilineInput
          label="Site Description:"
          placeholder="Description"
          value={sitedescription}
          onChange={handleSiteDescriptionChange}
        />
        <LabeledSelect
          label="Site Type:"
          options={sitetypes}
          isDisabled={false}
          isLoading={loadingSiteTypes}
          isClearable={true}
          isSearchable={true}
          name="sitetypeselect"
          onChange={handleSiteTypeChange}
          value={sitetype}
        />
        <LabeledSelect
          label="Client:"
          options={clients}
          isDisabled={false}
          isLoading={loadingClients}
          isClearable={true}
          isSearchable={true}
          name="clientsselect"
          onChange={handleClientChange}
          value={client}
        />
        <LabeledInput
          label="Client Region:"
          placeholder="Region"
          value={clientregion}
          onChange={handleClientRegionChange}
        />
        <LabeledInput
          label="Area:"
          placeholder="Site Area"
          value={area}
          onChange={handleAreaChange}
        />
        <LabeledInput
          label="HASL:"
          placeholder="0"
          value={hasl}
          onChange={handleHaslChange}
        />
        <p>Physical Address:</p>
        <LabeledInput
          label="Address Name:"
          placeholder="ABC Site Alpha Address"
          value={addressname}
          onChange={handleAddressNameChange}
        />
        <LabeledMultilineInput
          label="Address Description:"
          placeholder="Description"
          value={addressdescription}
          onChange={handleAddressDescriptionChange}
        />
        <LabeledInput
          label="Unit Number:"
          placeholder="0"
          value={unitnumber}
          onChange={handleUnitNumberChange}
        />
        <LabeledInput
          label="Building:"
          placeholder=""
          value={building}
          onChange={handleBuildingChange}
        />
        <LabeledInput
          label="Complex:"
          placeholder=""
          value={complex}
          onChange={handleComplexChange}
        />
        <LabeledInput
          label="Street:"
          placeholder=""
          value={street}
          onChange={handleStreetChange}
        />
        <LabeledInput
          label="Intersection:"
          placeholder="Intersects With..."
          value={intersection}
          onChange={handleIntersectionChange}
        />
        <LabeledMultilineInput
          label="Additional Details:"
          placeholder="Use this for Plus Code"
          value={addresslinetwo}
          onChange={handleAddressLineTwoChange}
        />
        <LabeledInput
          label="Suburb:"
          placeholder=""
          value={suburb}
          onChange={handleSuburbChange}
        />
        <LabeledInput
          label="Extension:"
          placeholder="Suburb's extension"
          value={extension}
          onChange={handleExtensionChange}
        />
        <LabeledInput
          label="City:"
          placeholder="Randburg"
          value={city}
          onChange={handleCityChange}
        />
        <LabeledInput
          label="Province:"
          placeholder="Gauteng"
          value={province}
          onChange={handleProvinceChange}
        />
        <LabeledSelect
          label="Country:"
          options={countries}
          isDisabled={false}
          isLoading={loadingCountries}
          isClearable={true}
          isSearchable={true}
          name="countryselect"
          onChange={handleCountryChange}
          value={country}
        />
        <LabeledInput
          label="ZIP:"
          placeholder="Postal code"
          value={postalcode}
          onChange={handlePostalCodeChange}
        />
        <LabeledInput
          label="Latitude:"
          placeholder="-23.12345"
          value={latitude}
          onChange={handleLatitudeChange}
        />
        <LabeledInput
          label="Longitude:"
          placeholder="28.12345"
          value={longitude}
          onChange={handleLongitudeChange}
        />
        <LabeledInput
          label="What Three Words:"
          placeholder="///what.three.words"
          value={whatthreewords}
          onChange={handleWhatThreeWordsChange}
        />
        <LabeledMultilineInput
          label="Access Instructions:"
          placeholder="Special instructions to access site"
          value={accessinstructions}
          onChange={handleAccessInstructionsChange}
        />
      </SideFormContainer>
      <ItemDetailContainer nopadding>
        
      </ItemDetailContainer>
      <FullPageLoader isLoading={creating} />
    </div>
  );
}

export default SitesNew;
import CryptoJS from "crypto-js";
import { AES_IV, AES_KEY } from "../config/setupEnv";

export default class AesEncryptionHelper {
  static encrypt(plainText) {
    const key = CryptoJS.enc.Hex.parse(AES_KEY);
    const iv = CryptoJS.enc.Hex.parse(AES_IV);
    const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv });
    return encrypted.toString();
  }

  static decrypt(encryptedText) {
    const key = CryptoJS.enc.Hex.parse(AES_KEY);
    const iv = CryptoJS.enc.Hex.parse(AES_IV);
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
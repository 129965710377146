import React, { useState } from 'react';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import SideFormContainer from '../../../../components/containers/SideFormContainer';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import './clients-new.scss';

const ClientsNew = () => {
  const [creating, setCreating] = useState(false);

  const handleSubmit = () => {
    console.log("Adding a new client");
  }

  return (
    <div className='clients-new-container'>
      <SideFormContainer
      formtitle="Register New Client Relationship"
      icon={faUsers}
      buttonTitle="Create Client"
      loading={creating}
      onSubmit={handleSubmit}
      fancy
      >

      </SideFormContainer>
      <ItemDetailContainer nopadding>

      </ItemDetailContainer>
    </div>
  );
}

export default ClientsNew;
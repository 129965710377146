import React, { createContext, useContext, useEffect, useState } from 'react';
import { SternaNavigator } from './SternaNavigator';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const sternaNavigator = new SternaNavigator();
  const [router, setRouter] = useState(sternaNavigator.setupRoutes());

  useEffect(() => {
    const unsubscribe = sternaNavigator.subscribeToChanges(setRouter);
    return unsubscribe;
  }, []);

  return (
    <NavigationContext.Provider value={{ sternaNavigator, router }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);

export const tryParseInt = (value) => {
  const result = parseInt(value, 10);
  return {
    success: !isNaN(result),
    value: result
  };
}

export const tryParseFloat = (value) => {
  const result = parseFloat(value);
  return {
    success: !isNaN(result),
    value: result
  };
}
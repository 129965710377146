import React from 'react';
import './dualcolumn.scss';

const DualColumn = ({ leftChildren, rightChildren }) => {
  return (
    <div className="dual-column-container">
      <div className='column'>
        {leftChildren}
      </div>
      <div className='column'>
        {rightChildren}
      </div>
    </div>
  );
};

export default DualColumn;

import React, { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import ApiService from '../../../../auth/ApiService';
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import ErrorHandling from "../../../../utils/errors/ErrorHandling";
import SideListContainer from "../../../../components/containers/SideListContainer";
import RouteItem from '../../../../components/listitems/RouteItem';
import ItemDetailContainer from "../../../../components/containers/ItemDetailContainer";
import TitleSectionComplete from "../../../../components/sections/itemdetailsections/other/header/TitleSectionComplete";
import RouteDetail from "../../../../components/sections/itemdetailsections/route/RouteDetail";
import JobsDetailExpanded from "../../../../components/sections/itemdetailsections/job/JobsDetailExpanded";
import NoItem from "../../../../components/listitems/NoItem";
import './routes-released.scss';
import LoadingOverlay from "../../../../components/widgets/FullPageLoader";

const RoutesReleased = () => {
  const [loading, setLoading] = useState(true);
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [routeStatuses, setRouteStatuses] = useState([]);
  const [routePriorities, setRoutePriorities] = useState([]);

  const [selectedRoute, setSelectedRoute] = useState();
  const [routeDetail, setRouteDetail] = useState();

  const [jobTypes, setJobTypes] = useState();
  const [jobPriorities, setJobPriorities] = useState();

  const [routeUpdateTrigger, setRouteUpdateTrigger] = useState(0);

  // SideListContainer shifting:
  const [isVisible, setIsVisible] = useState(true);

  // SignalR:
  const [connection, setConnection] = useState(undefined);
  const [connectionState, setConnectionState] = useState();
  const [connectionId, setConnectionId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await ApiService.get('routes');
        if (response?.data?.routes) {
          const releasedRoutes = response.data.routes.filter(route => route.routeStatus === "Released");
          if (releasedRoutes.length > 0) {
            setRoutes(releasedRoutes);
            setSelectedRoute(releasedRoutes[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching routes: ', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRouteStatuses = async () => {
      try {
        const response = await ApiService.get('enums/Route/RouteStatus');
        if (response?.data?.options) {
          setRouteStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching route statuses: ', error);
      }
    }

    const fetchRoutePriorities = async () => {
      try {
        const response = await ApiService.get('enums/Route/RoutePriority');
        if (response?.data?.options) {
          setRoutePriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching route priorities: ', error);
      }
    }

    const fetchJobTypes = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobType');
        if (response?.data?.options) {
          setJobTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job types: ', error);
      }
    }

    const fetchJobPriorities = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobPriority');
        if (response?.data?.options) {
          setJobPriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job priorities: ', error);
      }
    }

    fetchData();
    fetchRouteStatuses();
    fetchRoutePriorities();
    fetchJobTypes();
    fetchJobPriorities();

    // Set SignalR connection:
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_BASE_URL}/ws/Route/GetCompanyRouteQuery`, { accessTokenFactory: () => localStorage.getItem("sternatoken") ?? "" })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    return () => {
      if (connection) {
        connection.stop()
          .then(() => console.log('Connection stopped'))
          .catch(err => console.log('Error while stopping connection: ', err));
      }
    };
  }, []);

  // SignalR messages:
  useEffect(() => {
    console.log("Change in connection: ", connection);
    if (connection) {
      connection.on("GetCompanyRouteQueryHandlerProcessed", (response) => {
        console.log("WS Response: ", response);
        if (!response?.route) {
          ErrorHandling.toastifyError(response?.message ?? "Error loading route!");
        } else {
          setRouteDetail(response.route);
        }
        setLoadingRoute(false);
      });

      connection.start().then(() => {
        console.log('Connected!');
        setConnectionId(connection?.connection?.connectionId);
        setConnectionState(connection?.connection['_connectionState']);
        setRouteUpdateTrigger(prevTrigger => prevTrigger + 1); // Trigger fetching of default route detail
      }).catch(e => console.log("Connection failed: ", e));
    }
  }, [connection]);

  useEffect(() => {
    try {
      setLoadingRoute(true);
      // New SignalR way:
      if (selectedRoute && connection && connectionState === signalR.HubConnectionState.Connected) {
        const routeData = {
          routeId: selectedRoute.id,
          apiKey: process.env.REACT_APP_MASTER_API_KEY
          //userId: "c15342e4-b9ed-46d7-9da0-abef47e48e71"
        };
        console.log('Sending message to WS...');
        connection.invoke("Process", routeData).catch(err => console.error(err));

        const timeoutId = setTimeout(() => {
          if (loadingRoute) {
            setLoadingRoute(false);
            ErrorHandling.toastifyError('No response from server within the timeout period.');
            console.log('Timeout triggered');
          }
        }, 10000); // Timeout set for 10 seconds
      }
    } catch (error) {
      console.error('Error getting route detail: ', error);
      setLoadingRoute(false);
    }
  }, [selectedRoute, routeUpdateTrigger]);

  const toggleSidebar = () => {
    setIsVisible(!isVisible);
  }

  const completeRoute = () => {
    console.log(`Completing route ${selectedRoute.id}`);
  }

  return (
    <div className="routes-released-container">
      <LoadingOverlay isLoading={loadingRoute} />
      <SideListContainer loading={loading} semitransparentbg canminimize isVisible={isVisible} toggleSidebar={toggleSidebar}>
        {routes.length > 0 ? routes.map((route, index) => (
          <RouteItem key={index} route={route} routeStatuses={routeStatuses} selectRoute={setSelectedRoute} />
        )) : <NoItem title="📭 No Released Routes" />}
      </SideListContainer>
      <ItemDetailContainer>
        {!routeDetail ? <p>No Route Selected</p> : <>
          <TitleSectionComplete lighttitle title={routeDetail.routeName} icon={faTruck} completeMethod={completeRoute} canComplete={false} />
          {!routeDetail ? null : <RouteDetail route={routeDetail} routeStatuses={routeStatuses} routePriorities={routePriorities} />}
          {/* {!routeDetail.jobs ? null : <JobsDetail jobs={routeDetail.jobs} jobTypes={jobTypes} jobPriorities={jobPriorities} onDelink={delinkJobFromRoute} />} */}
          {!routeDetail.jobs ? null : <JobsDetailExpanded jobs={routeDetail.jobs} jobTypes={jobTypes} loading={false} />}
        </>}
      </ItemDetailContainer>
    </div>
  );
}

export default RoutesReleased;

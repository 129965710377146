import React, { useState, useEffect } from 'react';
import ApiService from '../../../../auth/ApiService';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import SideListContainer from '../../../../components/containers/SideListContainer';
import JobItem from '../../../../components/listitems/JobItem';
import ItemDetailContainer from '../../../../components/containers/ItemDetailContainer';
import TitleSection from '../../../../components/sections/itemdetailsections/other/header/TitleSection';
import SiteDetail from '../../../../components/sections/itemdetailsections/site/SiteDetail';
import { toast } from 'react-toastify';
import JobDetail from '../../../../components/sections/itemdetailsections/job/JobDetail';
import SendJobToRoute from '../../../../components/sections/itemdetailsections/job/SendJobToRoute';
import NoItem from '../../../../components/listitems/NoItem';
import './jobs-open.scss';
import DualColumn from '../../../../components/containers/DualColumn';

const JobsOpen = () => {
  const [jobs, setJobs] = useState([]);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobPriorities, setJobPriorities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedJob, setSelectedJob] = useState();
  const [jobDetail, setJobDetail] = useState();
  const [siteDetail, setSiteDetail] = useState();
  const [siteTypes, setSiteTypes] = useState();
  const [selectedRoute, setSelectedRoute] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await ApiService.get('jobs');
        if (response?.data?.jobs) {
          const openJobs = response.data.jobs.filter(job => job.JobStatus === 1 || job.jobStatus === "Pending");
          setJobs(openJobs);
          if (openJobs.length > 0) {
            setSelectedJob(openJobs[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching open jobs: ', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchJobStatuses = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobStatus');
        if (response?.data?.options) {
          setJobStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job statuses: ', error);
      }
    }

    const fetchJobTypes = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobType');
        if (response?.data?.options) {
          setJobTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job types: ', error);
      }
    }

    const fetchJobPriorities = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobPriority');
        if (response?.data?.options) {
          setJobPriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job priorities: ', error);
      }
    }

    const fetchSiteTypes = async () => {
      try {
        const response = await ApiService.get('enums/Site/SiteType');
        if (response?.data?.options) {
          setSiteTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching site types: ', error);
      }
    }

    fetchData();
    fetchJobStatuses();
    fetchJobTypes();
    fetchJobPriorities();
    fetchSiteTypes();
  }, []);

  useEffect(() => {
    const fetchJobDetail = async () => {
      if (selectedJob) {
        try {
          const response = await ApiService.get(`jobs/${selectedJob.id}`);
          if (response?.data?.job) {
            setJobDetail(response.data.job);
          }
        } catch (error) {
          console.error(`Error fetching job detail for job ${selectedJob.id}: `, error);
        }
      }
    };

    const fetchSiteDetail = async () => {
      if (selectedJob) {
        setSiteDetail(null);
        try {
          const response = await ApiService.get(`sites/${selectedJob.siteId}`);
          if (response?.data?.site) {
            setSiteDetail(response.data.site);
          }
        } catch (error) {
          console.error(`Error fetching site detail for job ${selectedJob.id}: `, error);
        }
      }
    }

    fetchJobDetail();
    fetchSiteDetail();
  }, [selectedJob]);

  const deleteJob = async () => {
    try {
      const response = await ApiService.delete(`jobs/${jobDetail.id}`, {
        apiKey: process.env.REACT_APP_MASTER_API_KEY
      });
      if (response?.data?.jobId) {
        setJobs(jobs.filter(job => job.id !== jobDetail.id));

        if (selectedJob && selectedJob?.id === jobDetail.id) {
          setSelectedJob(null);
          setJobDetail(null);
          setSiteDetail(null);
        }

        toast.success("Job Deleted!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          icon: "🎉"
        });
      }
    } catch (error) {
      toast.error("Job Deletion Failed!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        icon: "🤔"
      });
    }
  }

  const onRouteSelect = (route) => {
    setSelectedRoute(route);
  }

  const sendJobToRoute = async () => {
    if (!selectedJob?.id || !selectedRoute?.value) {
      return toast.error("Job Connection Failed!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        icon: "🤔"
      });
    }

    try {
      const response = await ApiService.post(`Jobs/route`, {
        apiKey: process.env.REACT_APP_MASTER_API_KEY,
        jobId: selectedJob.id,
        routeId: selectedRoute.value
      });
      
      if (!response?.data?.message) {
        return toast.error("Unknown Server Error!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          icon: "🤔"
        });
      }

      if (response.data.message !== "Link Success") {
        return toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          icon: "🤔"
        });
      }

      setJobs(jobs.filter(job => job.id !== jobDetail.id));
      
      if (selectedJob && selectedJob?.id === jobDetail.id) {
        setSelectedJob(null);
        setJobDetail(null);
        setSiteDetail(null);
      }

      toast.success("Job Linked!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        icon: "🎉"
      });
    } catch (error) {
      return toast.error("Unknown Error!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        icon: "🤔"
      });
    }
  }

  return (
    <div className='jobs-open-container'>
      <SideListContainer loading={loading} semitransparentbg>
        {jobs.length > 0 ? jobs.map((job, index) => (
          <JobItem key={index} job={job} jobStatuses={jobStatuses} selectJob={setSelectedJob} showCreateDate />
        )) : <NoItem title="📭 No Open Jobs"/>}
      </SideListContainer>
      <ItemDetailContainer>
        {!jobDetail ? <p>No Job Selected</p> : <>
          <TitleSection lighttitle title={jobDetail.jobDescription} icon={faBriefcase} deleteMethod={deleteJob} />
          <DualColumn
            leftChildren={!jobDetail ? null : <JobDetail job={jobDetail} jobTypes={jobTypes} jobPriorities={jobPriorities} />}
            rightChildren={!siteDetail ? null : <SiteDetail site={siteDetail} siteTypes={siteTypes} />}
          />
          {jobDetail?.jobStatus !== "Pending" ? null : <SendJobToRoute selectedRoute={selectedRoute} onRouteSelect={onRouteSelect} onSubmit={sendJobToRoute} />}
        </>}
      </ItemDetailContainer>
    </div>
  );
}

export default JobsOpen;

import React from "react";
import TopContainer from "../../../../components/containers/TopContainer";
import SubNavContainer from "../../../../components/containers/SubNavContainer";
import { Outlet, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";

const Tickets = ({ children }) => {
  return (
    <>
      <TopContainer />
      <SubNavContainer>
        <ul>
          <li><Link to={`/sterna/dashboard/tickets/open`}><FontAwesomeIcon icon={faFolderOpen} className="awesome-icon"/> Open Tickets</Link></li>
        </ul>
      </SubNavContainer>
      <div className="operations-section">
        <Outlet />
      </div>
    </>
  );
}

export default Tickets;

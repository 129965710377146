import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../scss/components/formcomponents.scss";

const LabeledDateTimePicker = ({ label, date, onSelect, onChange, dateFormat = "Pp" }) => (
  <div className="labeled-input">
    {label && <label>{label}</label>}
    <DatePicker
      selected={date}
      onSelect={onSelect}
      onChange={onChange}
      showTimeSelect
      dateFormat={dateFormat}
      className="text-input"
    />
  </div>
);

export default LabeledDateTimePicker;

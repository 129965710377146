import { BaseHelper } from "../base/BaseHelper";
import { API_BASE_URL, MASTER_API_KEY } from "../config/setupEnv";
import AesEncryptionHelper from "../crypto/AesEncryptionHelper";

export class SternaAuth extends BaseHelper {
  constructor() {
    super();
    this.token = localStorage.getItem('sternatoken') ?? 'nothing';
    this.isAuthenticated = false;
    this.subscribers = [];
  }

  subscribeToChanges(callback) {
    this.subscribers.push(callback);
    return () => {
      this.subscribers = this.subscribers.filter(sub => sub !== callback);
    };
  }

  notifySubscribers() {
    this.subscribers.forEach(callback => callback(this.isAuthenticated));
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  async checkAuthentication() {
    const url = `${API_BASE_URL}/api/auth`;

    try {
      const response = await this.makeGetRequest(url, {
        params: {
          token: this.token,
          apiKey: MASTER_API_KEY
        }
      });

      if (response?.data) {
        console.log("Auth data: ", response.data);
        this.isAuthenticated = response.data.isAuthenticated;
        return this.isAuthenticated;
      }

      this.isAuthenticated = false;
      return this.isAuthenticated;
    } catch (error) {
      console.error('Error checking authentication: ', error);
      this.isAuthenticated = false;
      return this.isAuthenticated;
    }
  };

  async initiateLogin(email, password) {
    const url = `${API_BASE_URL}/api/auth/login`;

    try {
      const encryptedEmail = AesEncryptionHelper.encrypt(email);
      const encryptedPassword = AesEncryptionHelper.encrypt(password);
      const response = await this.makePostRequest(url, {
        encryptedEmail,
        encryptedPassword
      });
      if (response?.data) {
        console.log('Sending Request... Response data: ', response.data);
        this.token = response.data.token ?? 'a';
        const authSuccess = await this.checkAuthentication();
        const claims = this.parseJwt(this.token);
        console.log("Claims: ", claims); // We can use claims to determine the route to set

        // Set route here based on claims...
        
        if (authSuccess) {
          localStorage.setItem('sternatoken', response.data.token);
          this.isAuthenticated = true;
          return response.data.loginSuccess;
        }

        localStorage.removeItem('sternatoken');
        this.isAuthenticated = false;
        return false;
      }

      this.isAuthenticated = false;
      return false;
    } catch (error) {
      console.error('Error initiating login: ', error);
      this.isAuthenticated = false;
      return false;
    }
  }

  parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Error parsing JWT: ", e);
      return null;
    }
  }
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-selection-viewer {
  display: flex;
  width: 100%;
  flex: 1 1; }
`, "",{"version":3,"sources":["webpack://./src/components/sections/itemdetailsections/report/ReportSelectionViewer/report-selection-viewer.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,WAAW;EACX,SAAO,EAAA","sourcesContent":[".report-selection-viewer {\n    display: flex;\n    width: 100%;\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

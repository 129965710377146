import React, { useState, useEffect } from "react";
import ApiService from "../../../../auth/ApiService";
import ErrorHandling from "../../../../utils/errors/ErrorHandling";
import { faTruck, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import SideListContainer from "../../../../components/containers/SideListContainer";
import TitleSection from "../../../../components/sections/itemdetailsections/other/header/TitleSection";
import RouteItem from '../../../../components/listitems/RouteItem';
import ItemDetailContainer from "../../../../components/containers/ItemDetailContainer";
import RouteDetail from "../../../../components/sections/itemdetailsections/route/RouteDetail";
import JobsDetail from "../../../../components/sections/itemdetailsections/job/JobsDetail";
import NoItem from "../../../../components/listitems/NoItem";
import "./routes-approved.scss";
import SimpleButton from "../../../../components/buttons/SimpleButton";

const RoutesApproved = () => {
  const [loading, setLoading] = useState(true);
  const [routes, setRoutes] = useState([]);
  const [routeStatuses, setRouteStatuses] = useState([]);
  const [routePriorities, setRoutePriorities] = useState([]);

  const [selectedRoute, setSelectedRoute] = useState();
  const [routeDetail, setRouteDetail] = useState();

  const [jobTypes, setJobTypes] = useState();
  const [jobPriorities, setJobPriorities] = useState();

  const [routeUpdateTrigger, setRouteUpdateTrigger] = useState(0);
  const [releasing, setReleasing] = useState(false);
  const [canBePlanned, setCanBePlanned] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.get('routes');
        if (response?.data?.routes) {
          const approvedRoutes = response.data.routes.filter(route => route.routeStatus === "Approved");
          if (approvedRoutes.length > 0) {
            setRoutes(approvedRoutes);
            setSelectedRoute(approvedRoutes[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching routes: ', error);
      }
    };

    const fetchRouteStatuses = async () => {
      try {
        const response = await ApiService.get('enums/Route/RouteStatus');
        if (response?.data?.options) {
          setRouteStatuses(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching route statuses: ', error);
      }
    }

    const fetchRoutePriorities = async () => {
      try {
        const response = await ApiService.get('enums/Route/RoutePriority');
        if (response?.data?.options) {
          setRoutePriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching route priorities: ', error);
      }
    }

    const fetchJobTypes = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobType');
        if (response?.data?.options) {
          setJobTypes(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job types: ', error);
      }
    }

    const fetchJobPriorities = async () => {
      try {
        const response = await ApiService.get('enums/Job/JobPriority');
        if (response?.data?.options) {
          setJobPriorities(response.data.options);
        }
      } catch (error) {
        console.error('Error fetching job priorities: ', error);
      }
    }

    const fetchAllData = async () => {
      setLoading(true);
  
      await Promise.all([
        fetchData(),
        fetchRouteStatuses(),
        fetchRoutePriorities(),
        fetchJobTypes(),
        fetchJobPriorities(),
      ]);
  
      setLoading(false);
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    const fetchSelectedRoute = async () => {
      if (selectedRoute) {
        try {
          const response = await ApiService.get(`routes/${selectedRoute.id}`);
          if (response?.data?.route) {
            console.log("Route detail: ", response.data.route);
            setRouteDetail(response.data.route);
          }
        } catch (error) {
          console.error('Error fetching route detail: ', error);
        }
      }
    }

    fetchSelectedRoute();
  }, [selectedRoute, routeUpdateTrigger]);

  const releaseRoute = async () => {
    // This has been set for optimisation failures for now.
    // The logic should be reworked for true planned routes,
    // in other words, checks must precede route optimisation and release.
    try {
      setReleasing(true);
      if (selectedRoute) {
        // We first need to optimise the route:
        const optimizationResponse = await ApiService.post("Routes/optimize", {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          routeId: selectedRoute.id
        });
        if (!optimizationResponse?.data?.journey) {
          return ErrorHandling.toastifyError(optimizationResponse.data.message ?? "Optimization failed!");
        }
        const releaseResponse = await ApiService.patch("Routes/status/released", {
          apiKey: process.env.REACT_APP_MASTER_API_KEY,
          routeId: selectedRoute.id
        });
        if (!releaseResponse?.data?.routeStatus === "Released") {
          return ErrorHandling.toastifyError(optimizationResponse.data.message ?? "Route failed to release!");
        }

        setRoutes(routes.filter(rt => rt.id !== selectedRoute.id));
        setSelectedRoute(null);
        setRouteDetail(null);
        return ErrorHandling.toastifySuccess("Route Released");
      }
    } catch (error) {
      ErrorHandling.toastifyError("Route failed to optimize and release!");
    } finally {
      setReleasing(false);
    }
  }

  const markRouteAsPlanned = () => {
    console.log(`Mark route as planned ${selectedRoute.id}`);
  }

  const completeRoute = () => {
    console.log(`Completing route ${selectedRoute.id}`);
  }

  return (
    <div className="routes-approved-container">
      <SideListContainer loading={loading} semitransparentbg>
        {routes.length > 0 ? routes.map((route, index) => (
          <RouteItem key={index} route={route} routeStatuses={routeStatuses} selectRoute={setSelectedRoute} />
        )) : <NoItem title="📭 No Approved Routes"/>}
      </SideListContainer>
      <ItemDetailContainer>
        {!routeDetail ? <p>No Route Selected</p> : <>
          <TitleSection lighttitle title={routeDetail.routeName} icon={faTruck} completeMethod={completeRoute} canDelete={false}>
            <SimpleButton title="Mark As Planned" icon={faClipboardList} disabled={!canBePlanned} onClick={markRouteAsPlanned} />
          </TitleSection>
          {!routeDetail ? null : <RouteDetail route={routeDetail} routeStatuses={routeStatuses} routePriorities={routePriorities} />}
          {!routeDetail.jobs ? null : <JobsDetail jobs={routeDetail.jobs} jobTypes={jobTypes} jobPriorities={jobPriorities} noDelink entityToEdit="Job Card" />}
          {/* <RouteRelease canRelease={true} onSubmit={releaseRoute} /> */}
        </>}
      </ItemDetailContainer>
    </div>
  );
}

export default RoutesApproved;
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faCar, faFileInvoiceDollar, faCarCrash } from '@fortawesome/free-solid-svg-icons';
import TopContainer from '../../../../components/containers/TopContainer';
import SubNavContainer from '../../../../components/containers/SubNavContainer';

const Routes = () => {
  return (
    <>
      <TopContainer />
      <SubNavContainer>
        <ul>
          <li><Link to={`/sterna/dashboard/vehicles/new`}><FontAwesomeIcon icon={faPlusSquare} className='awesome-icon'/> New Vehicle</Link></li>
          <li><Link to={`/sterna/dashboard/vehicles/all`}><FontAwesomeIcon icon={faCar} className='awesome-icon'/> All Vehicles</Link></li>
          <li><Link to={`/sterna/dashboard/vehicles/fines`}><FontAwesomeIcon icon={faFileInvoiceDollar} className='awesome-icon'/> Fines</Link></li>
          <li><Link to={`/sterna/dashboard/vehicles/accidents`}><FontAwesomeIcon icon={faCarCrash} className='awesome-icon'/> Accidents</Link></li>
        </ul>
      </SubNavContainer>
      <div className='operations-section'>
        <Outlet />
      </div>
    </>
  );
};

export default Routes;
import React, { useState, useEffect } from "react";
import './report-selection-viewer.scss';
import ApiService from "../../../../../auth/ApiService";
import SideListContainer from "../../../../containers/SideListContainer";
import RouteReportItem from "../../../../listitems/reportitems/RouteReportItem";
import ItemDetailContainer from "../../../../containers/ItemDetailContainer";
import FullPageLoader from "../../../../widgets/FullPageLoader";

import {
  PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
  ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, Inject
} from '@syncfusion/ej2-react-pdfviewer';
import ErrorHandling from "../../../../../utils/errors/ErrorHandling";

const unapprovedReportToolbarItems = [
  'PageNavigationTool',
  'MagnificationTool',
  'PanTool',
  'SelectionTool',
  'SearchOption'
];

const approvedReportToolbarItems = [
  'PageNavigationTool',
  'MagnificationTool',
  'PanTool',
  'SelectionTool',
  'SearchOption',
  'DownloadOption'
];

const ReportSelectionViewer = ({ reports, triggerUpdate = () => {} }) => {
  const [selectedReport, setSelectedReport] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [pdfUrl, setPdfUrl] = useState("");
  const [anyApprovedReports, setAnyApprovedReports] = useState(false);
  const [approvingReport, setApprovingReport] = useState(false);
  const [toolbarToUse, setToolbarToUse] = useState(unapprovedReportToolbarItems);

  useEffect(() => {
    if (reports.find(x => x.approved === true)) {
      setAnyApprovedReports(true);
    }
  }, []);

  useEffect(() => {
    if (selectedReport?.reportUrl) {
      setPdfUrl(selectedReport.reportUrl);
    }
    if (selectedReport?.approved) {
      setToolbarToUse(approvedReportToolbarItems);
    } else {
      setToolbarToUse(unapprovedReportToolbarItems);
    }
  }, [selectedReport]);

  const reportItemSelect = (report, index) => {
    setSelectedReport(report);
    setSelectedIndex(index);
  }

  const ReportMenuBar = () => {
    return (
      <div>
        <p>{reports.length.toString()} Report{reports.length === 1 ? '' : 's'}</p>
      </div>
    );
  }

  const handleApproveReport = async (report, index) => {
    setApprovingReport(true);
    try {
      const approveResponse = await ApiService.put(`reports/${report.id}/approve`);
      if (approveResponse?.data?.message !== 'Report approved!') {
        console.error("Report not approved: ", approveResponse?.data?.message ?? approveResponse);
        return ErrorHandling.toastifyError("Failed to Approve Report!");
      }
      setAnyApprovedReports(true);
      triggerUpdate();
      setToolbarToUse(approvedReportToolbarItems);
      ErrorHandling.toastifySuccess("Report was Approved!");
    } catch {
      ErrorHandling.toastifyError("Failed to Approve Report!");
    } finally {
      setApprovingReport(false);
    }
  }

  return (
    <div className="report-selection-viewer">
      <SideListContainer loading={false} topComponent={<ReportMenuBar />}>
        {reports.length > 0 ? reports.map((report, index) =>
          <RouteReportItem
            key={index}
            report={report}
            index={index + 1}
            activeIndex={selectedIndex}
            select={reportItemSelect}
            handleApproveReport={handleApproveReport}
            anyApprovedReports={anyApprovedReports}
          />) :
          null}
      </SideListContainer>
      <ItemDetailContainer nopadding>
        {/* Render the PDF Viewer */}
        <PdfViewerComponent
          id="container"
          documentPath={pdfUrl ?? "https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf"}
          resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
          toolbarSettings={{
            showTooltip: true,
            toolbarItems: toolbarToUse
          }}
          style={{ 'height': '100%', 'width': '100%' }}>

          <Inject services={[Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView,
            ThumbnailView, TextSelection, TextSearch, FormFields, FormDesigner]} />

        </PdfViewerComponent>
      </ItemDetailContainer>
      <FullPageLoader isLoading={approvingReport} />
    </div>
  );
}

export default ReportSelectionViewer;

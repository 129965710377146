import React, { useState, useEffect } from 'react';
import ItemDetailContainer from '../../../../containers/ItemDetailContainer';
import SubNavContainer from '../../../../containers/SubNavContainer';
import './jobinformation.scss';
import TabRenderer from './Renderers/TabRenderer';

const JobInformation = ({ job, jobTypes, jobPriorities, jobItemStatuses, scaffold, scaffoldLoading, reports, triggerUpdate = () => { } }) => {
  const [tab, setTab] = useState(1);

  useEffect(() => {
    changeTab(1);
  }, [scaffold]);

  const changeTab = (tabIndex) => {
    setTab(tabIndex);
  }

  const canViewJobDetails = !scaffoldLoading;
  const canViewJobCard = !scaffoldLoading;
  const canViewReports = !scaffoldLoading && reports.length > 0;

  return (
    <>
      <ItemDetailContainer nopadding styles={{ backgroundColor: "#FFF" }}>
        <SubNavContainer somegive nobg={true}>
          <div className='job-information-tab-group'>
            <button type="button" className={canViewJobDetails ? "" : "disabled"} onClick={() => changeTab(1)} disabled={!canViewJobDetails}>📋 View Job Details</button>
            <button type="button" className={canViewJobCard ? "" : "disabled"} onClick={() => changeTab(2)} disabled={!canViewJobCard}>🛠 View Job Card</button>
            <button type="button" className={canViewReports ? "" : "disabled"} onClick={() => changeTab(3)} disabled={!canViewReports}>📄 View Reports</button>
          </div>
        </SubNavContainer>
        <TabRenderer
          job={job}
          scaffoldLoading={scaffoldLoading}
          scaffold={scaffold}
          jobTypes={jobTypes}
          jobPriorities={jobPriorities}
          jobItemStatuses={jobItemStatuses}
          reports={reports}
          triggerUpdate={triggerUpdate}
          tab={tab}
        />
      </ItemDetailContainer>
    </>
  );
}

export default JobInformation;

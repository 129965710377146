import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import "../../scss/components/formcomponents.scss";
import "../../scss/components/buttons.scss";

const SideFormContainer = ({
  formtitle,
  icon = faPlusSquare,
  onSubmit,
  buttonTitle = "Submit",
  loading = false,
  fancy = false,
  children
}) => {
  const containerClass = !fancy ? 'sideformcontainer' : 'sideformcontainer fancy';

  return (
    <div className={containerClass}>
      <h1><FontAwesomeIcon icon={icon} className="title-icon" /> {formtitle}</h1>
      <form className="sideform" onSubmit={onSubmit}>
        {children}
        <button className="submit-button" type="submit" disabled={loading}>{loading ? "Please wait..." : buttonTitle}</button>
      </form>
    </div>
  );
}

export default SideFormContainer;